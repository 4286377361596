import React from 'react'
import View from 'views/Worker/Worker'
import WorkerAction from '../../actions/WorkerAction'
import RequestAction from '../../actions/RequestAction'
import UserAction from '../../actions/UserAction'
import HistoryAction from '../../actions/HistoryAction'
import BaseContainer, { selector } from 'containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
class Worker extends BaseContainer {
    constructor(props) {
        super(props)
        this.state = {
            reload: false, 
            historyFlagSlide: undefined
        }
        this.flagChecked = false
        this.edit = false // có 2 màn là màn sửa và màn xác nhận, biến edit để phân biệt 2 màn
        this.onCheckIn = this.onCheckIn.bind(this)
        this.onCheckOut = this.onCheckOut.bind(this)
        this.onUpdateCheckingTime = this.onUpdateCheckingTime.bind(this)
        this.onUpdateFlagWorking = this.onUpdateFlagWorking.bind(this)
        this.onUpdateAlcohol = this.onUpdateAlcohol.bind(this)
        this.onUpdateHistory = this.onUpdateHistory.bind(this)
    }

    componentDidMount() {
        localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
        localStorage.setItem('dateCalendar', new Date()) 
        let _id = JSON.parse(localStorage.user)._id
        this.fetchInit()

        this.props.dispatch(UserAction.fetch({ _id: _id }))
        this.props.dispatch(RequestAction.fetchAll({ pageSize: -1, flag: 'workers' }))
    }

    fetchInit = () => {
        let offsetInHours = new Date().getTimezoneOffset() / 60;
        this.props.dispatch(WorkerAction.initCheckTime({
            date: moment().format("YYYY-MM-DD"),
            offsetInHours: offsetInHours,
            currentTime: new Date()
        })).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            } 
        })
    }

    updateConstructions = (constructions = []) => {
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        let slideTime = localStorage.getItem('slideWorking')
        slideTime = slideTime === 'true' ? true : false

        constructions = constructions.map(item => {
            if(!item.constructionId) return {...item, constructionId: item._id}
            return item
        })
        let { date } = this.props.initCheckTime;
        if(!date) return
        let permissions = JSON.parse(localStorage.user).permissions || []
        this.props.dispatch(WorkerAction.updateConstructions({ date, constructions: constructions, slideTime: slideTime, permissions: permissions, checkTimeZoneFE: checkTimeZoneFE })).then(data => {
            if (!data.error) {
                this.fetchInit()
            } else {

                let err = data.error
                switch (err.status) {
                    default: this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
                }
            }
        })
    }

    onCheckIn(values) {
        if(this.flagChecked) return
        this.flagChecked = true
        let { date } = this.props.initCheckTime
        let permissions = JSON.parse(localStorage.user).permissions || []
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");

        let slideTime = localStorage.getItem('slideWorking')
        slideTime = slideTime === 'true' ? true : false

        let offsetInHours = new Date().getTimezoneOffset() / 60;

        values = {
            ...values,
            date: date,
            constructions: values.constructions,
            isoDate: moment(date).startOf('day'),
            slideTime: slideTime,
            permissions: permissions,
            offsetInHours: offsetInHours,
            checkTimeZoneFE: checkTimeZoneFE
        }
        this.props.dispatch(WorkerAction.checkIn(values))
            .then(data => {
                if (!data.error) {
                    this.fetchInit()
                    this.notify(I18n.t('Message.createDataSuccess'))
                } else {
                    let err = data.error
                    switch (err.status) {
                        case 400: {
                            if (err.message === "User_Not_Exist") {
                                this.notify(I18n.t("Backend.Worker.User_Not_Exist"), "error")
                            }
                            if (err.message === "No_Position") {
                                this.notify(I18n.t("Backend.Worker.No_Position"), "error")
                            }
                            if (err.message === "Check_In_Once") {
                                this.notify(I18n.t("Backend.Worker.Check_In_Once"), "error")
                            }
                            if (err.message === "ExistCheckinCheckout") {
                                this.notify(I18n.t("Backend.Worker.ExistCheckinCheckout"), "error")
                            }
                            break;
                        }
                        default: this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
                    }
                }
            })
    }

    setCheckoutTime = () => {
        let { date, endTime } = this.props.initCheckTime

        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        let slideTime = localStorage.getItem('slideWorking')
        slideTime = slideTime === 'true' ? true : false
        let offsetInHours = new Date().getTimezoneOffset() / 60;

        if(endTime) return false
        let permissions = JSON.parse(localStorage.user).permissions || []
        this.props.dispatch(WorkerAction.setCheckoutTime({
            date: date,
            slideTime: slideTime,
            permissions: permissions,
            offsetInHours: offsetInHours,
            checkTimeZoneFE: checkTimeZoneFE
        })).then(data => {
            if (data.error) {
                this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
            } 
            else {
                this.fetchInit()
            }
        })
    }

    onUpdateFlagWorking(params) {
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        this.props.dispatch(WorkerAction.updateSlideWorking({...params, checkTimeZoneFE: checkTimeZoneFE})).then(response => {
            if(!response.error) {
                this.setState({ historyFlagSlide: response.data })
            }
        })
    }

    onUpdateAlcohol(params) {
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        this.props.dispatch(WorkerAction.updateAlcohol({...params, checkTimeZoneFE: checkTimeZoneFE})).then(response => {
            if(!response.error) {
                this.setState({ alcohol: response.data })
            }
        })
    }

    onUpdateCheckingTime(params, cb) {
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        let permissions = JSON.parse(localStorage.user).permissions || []
        this.props.dispatch(WorkerAction.updateCheckingTime({...params, id: this.props.initCheckTime._id, permissions: permissions, checkTimeZoneFE: checkTimeZoneFE}))
            .then(response => {
                if (!response.error) {
                    this.fetchInit()
                    this.notify(I18n.t('Message.editDataSuccess'))
                    cb()
                } else {
                    this.notify(`Response: [${response.error.status}] ${response.error.message}`, 'error')
                }
            })
    }

    showMessage(res) {
        if (!res.error) {
            this.fetchInit()
            this.notify(I18n.t('Message.editDataSuccess'))
        } else {
            let err = res.error
            switch (err.status) {
                case 422: {
                    if (err.message === "Id_Required") {
                        this.notify(I18n.t("Backend.DbObject.Id_Required"), "error")
                    }
                    if (err.message === "Input_WorkTime_Warn") {
                        this.notify(I18n.t("Backend.History.Input_WorkTime_Warn"), "error")
                    }
                    break
                }
                case 404: {
                    if (err.message === "No_Object") {
                        this.notify(I18n.t("Backend.DbObject.No_Object"), "error")
                    }
                    break
                }
                case 405: {
                    if (err.message === "Expired_Update_Warn") {
                        this.notify(I18n.t("Backend.History.Expired_Update_Warn"), "error")
                    }
                    break
                }
                case 412: {
                    if (err.message === "Input_Construct_Warn") {
                        this.notify(I18n.t("Backend.History.Input_Construct_Warn"), "error")
                    }
                    break
                }
                case 417: {
                    if (err.message === "Block_Update") {
                        this.notify(I18n.t("Backend.History.Block_Update"), "error")
                    }
                    break
                }
                default: this.notify(`Response: [${err.status}] ${err.message}`, 'error')

            }
        }
    }

    onCheckOut(values) {
        let { date } = this.props.initCheckTime
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        let permissions = JSON.parse(localStorage.user).permissions || []
        let slideTime = localStorage.getItem('slideWorking')
        slideTime = slideTime === 'true' ? true : false

        values = {
            ...values,
            date: date,
            slideTime: slideTime,
            permissions: permissions,
            checkTimeZoneFE: checkTimeZoneFE
        }

        let { totalWork, totalTime, errorTotalHour } = values
        //check xem đã xác nhận tổng giờ lao động chưa
        if (errorTotalHour) {
            alert(I18n.t('Form.CheckInOut.totalHour'))
            this.notify(I18n.t('Form.CheckInOut.totalHour'), 'error')
            return false
        }
        //check xem thời gian nhập vao có lớn hơn thời gian tổng giờ làm không
        if (totalWork > totalTime) {
            this.notify(I18n.t('Form.CheckInOut.checkTime'), 'error')
            return false
        }

        this.props.dispatch(WorkerAction.checkOut(values))
            .then(data => {
                if (!data.error) {
                    this.props.history.push('/workers')
                    this.notify(I18n.t('Message.createDataSuccess'))
                    localStorage.removeItem('checkout_constructions')
                } else {
                    let err = data.error
                    switch (err.status) {
                        case 400: {
                            if (err.message === "Not_Check_In") {
                                this.notify(I18n.t("Backend.Worker.Not_Check_In"), "error")
                            }
                            if (err.message === "Expired_Update_Warning") {
                                this.notify(I18n.t("Backend.Worker.Expired_Update_Warning"), "error")
                            }
                            if (err.message === "Check_In_Once") {
                                this.notify(I18n.t("Backend.Worker.Check_In_Once"), "error")
                            }
                            break
                        }
                        case 422: {
                            if (err.message === "Update_Check_Out_Warning") {
                                this.notify(I18n.t("Backend.Worker.Update_Check_Out_Warning"), "error")
                            }
                            break
                        }
                        default: this.notify(`Response: [${data.error.status}] ${data.error.message}`, 'error')
                    }
                }
            })
    }

    onUpdateHistory(values) {
        let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
        this.props.dispatch(HistoryAction.updateWorkingLog({ _id: values.idRequest, ...values, checkTimeZoneFE: checkTimeZoneFE }))
            .then(data => {
                this.showMessage(data)  
            })
    }

    render() {   
        let { data } = this.props.data || {}
        //sửa trường hợp 2 người check our trên 1 trường trình duyệt
        let _id = JSON.parse(localStorage.user)._id
        if (data.userId !== _id) {
            data = {}
        }

        return (
            <View
                onUpdateCheckingTime={this.onUpdateCheckingTime}
                onUpdateFlagWorking={this.onUpdateFlagWorking}
                onUpdateAlcohol={this.onUpdateAlcohol}
                onUpdateHistory={this.onUpdateHistory}
                onCheckIn={this.onCheckIn}
                onCheckOut={this.onCheckOut}
                setCheckoutTime = {this.setCheckoutTime}
                data={this.props.data}
                user={this.props.user}
                flagChecked={this.flagChecked}
                initCheckTime = {this.props.initCheckTime || {}}
                getRequests={this.props.getRequests}
                requests={this.props.requests}
                updateConstructions={this.updateConstructions}
                historyFlagSlide={this.state.historyFlagSlide}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        //sử dụng selector để lấy state từ redux
        data: selector(state, "worker", {}),
        getRequests: selector(state, 'request.getRequests.data', []),
        initCheckTime: selector(state, 'worker.list.data', {}),
        user: selector(state, "user.item", {}),
    }
}

export default withRouter(connect(mapStateToProps)(Worker))