import {
   Grid,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   withStyles,
   Typography,
   ExpansionPanel,
   ExpansionPanelDetails,
   ExpansionPanelSummary,
   CardActions
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import BaseView from 'views/BaseView'
import { I18n } from 'react-redux-i18n'
import { Form, TextField, Validation } from 'components/Forms'
import TotalHour from './components/TotalHour'
import moment from 'moment'
import common from "common";
import PaperFade from "components/Main/PaperFade"
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import AutoCompleteField, { Option as OptionAuto } from 'components/Forms/AutoCompleteField'
import OptionList from 'components/Forms/OptionList'
import './style.css'
import Select from "react-select";

const styles = theme => ({
   gridTable: {
      height: "calc(100vh - 100px)"
   },
   fixButton: {
      right: '0 !important',
      position: 'absolute !important',

      [theme.breakpoints.down('sm')]: {
         fontSize: "14px",
         padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 1}px`,
      },
   },
   paper: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
      margin: 0
   },
   hourList: {
      marginTop: "10px",
      marginBottom: "10px"
   },
   Button: {
      height: "150px",
   },
   ButtonSmall: {
      height: "100px",
      [theme.breakpoints.down('xs')]: {
         display: "block"
      }
   },
   CheckInBtn: {
      backgroundColor: "#2196F3 !important"
   },
   CheckOutBtn: {
      backgroundColor: "#D6DF23 !important"
   },
   BaseBtn: {
      backgroundColor: "white !important"
   },
   SlideTrueBtn: {
      backgroundColor: "gray !important"
   },
   textPrimary: {
      [theme.breakpoints.down('xs')]: {
         right: '0 !important',
         position: 'absolute !important',
      }
   },
   ExpansionSummary: {
      [theme.breakpoints.down('xs')]: {
         padding: '0px 8px !important'
      }
   },
   SubmitBtn: {
      // backgroundColor: "#EC028D !important",
      color: '#ffffff',
      marginTop: `${theme.spacing.unit * 2}px`
   },
   EditBtn: {
      // backgroundColor: "#37B449 !important",
      color: '#ffffff',
      marginTop: `${theme.spacing.unit * 2}px`
   },
   GroupBtn: {
      marginBottom: '4px',
   },
   fixPanel: {
      [theme.breakpoints.down('xs')]: {
         padding: '0px 8px !important'
      }
   },
   spanText: {
      [theme.breakpoints.down('xs')]: {
         fontWeight: 'bold'
      }
   },
   spanCheck: {
      [theme.breakpoints.down('xs')]: {
         fontWeight: 'bold'
      }
   },
   textField: {
      marginTop: "-8px",
      marginBottom: "16px"
   },
   SlideReportArea: {
      position: "relative"
   },
   BackGroudSlideTime: {
      backgroundColor: "#d4edda !important",
   }
})

const customStyles = {
   option: (provided, state) => ({
     ...provided,
   //   borderBottom: '1px dotted pink',
     color: "black",
     padding: 15,
     cursor: "pointer",
   }),
   control:(styles) => ({ ...styles, 
      backgroundColor: 'white',
      borderWidth: "0 0 1px 0",
      borderStyle: "solid",
      borderColor: "#D2D2D2",
      borderRadius: "none",
      marginTop: "26px",
      marginBottom: "8px"
   })
 }
class Index extends BaseView {
   constructor(props) {
      super(props)
      let getSlideWorking = localStorage.getItem('slideWorking') === 'true' ? true : false

      this.state = {
         showHour: false,
         workTime: [],
         reload: false,
         constructionInput: '',
         totalTime: 0, //tổng thời gian làm việc tính theo milliseconds
         totalTimeInstruction: 0,
         slideWorking: getSlideWorking,
         hoursStart: [
            Validation.min(5, "最小値が 5 で入力してください。"),
            Validation.max(24, I18n.t("Form.max")),
         ],
         minutesStart: [
            Validation.min(0, "最小値が 0 で入力してください。"),
            Validation.max(60, I18n.t("Form.max")),
         ],
         alcohol: '',
      }
      this.edit = undefined
      this.validate = {
         hours: [
            Validation.min(0, I18n.t("Form.min")),
            Validation.max(24, I18n.t("Form.max")),
         ],
         minutes: [
            Validation.min(0, I18n.t("Form.min")),
            Validation.max(60, I18n.t("Form.max")),
         ],
         alcohol: [
           (value) => {
               const floatValue = parseFloat(value?.replace(",",""));
               if (value.startsWith('-') || isNaN(floatValue) || floatValue < 0 || (!/^\d*\.\d*$/.test(value) && value != 0)){
                  return I18n.t("Form.min").replace('${arguments[0]}', '0');
               }
            },
            (value) => {
               const floatValue = parseFloat(value?.replace(",",""));
               if (floatValue > 1) {
                  return I18n.t("Form.max").replace('${arguments[0]}', '1');
               }
            },
         ]
      }
      this.endTime = new Date()
      this.onSubmit = this.onSubmit.bind(this)
      this.onCheckOut = this.onCheckOut.bind(this)
      this.errorTotalHour = false //check xem nếu component total hour mà lỗi hoặc chưa xác nhận thì không cho gửi lên check out
      this.ConfirmDialog = null;
      this.ConfirmCheckout = null;
      this.onAddConstruction = this.onAddConstruction.bind(this)
      this.onUpdateCheckingTime = this.onUpdateCheckingTime.bind(this)
      this.changeSlideWorking = this.changeSlideWorking.bind(this)
      this.changeAlcohol = this.changeAlcohol.bind(this)
      this.onCancelDriverLog = this.onCancelDriverLog.bind(this)
      this.onChangeDriverLog = this.onChangeDriverLog.bind(this)
   }

   componentWillUpdate(nextProps) {
      let { totalTime = 0, checkOutStatus = false , startTime, endTime } = nextProps.initCheckTime      
      if (totalTime !== undefined && (this.state.totalTime !== totalTime)){
         this.setState({
            totalTime: totalTime,
         })
      } 
      if(endTime !== undefined) this.state.checking = {  startTime: startTime, endTime: endTime }
      if (checkOutStatus && this.edit === undefined) this.edit = true
   }

   //lưu lại list construction người dùng thêm vào tạm thời, để qua màn đó không bị mất
   componentWillUnmount() {
      const { constructions = [] } = this.props.initCheckTime
      if (Array.isArray(constructions)) {
         localStorage.setItem('checkout_constructions', JSON.stringify(constructions.map(item => item.constructionId)))
      }
   }

   //merge construction
   mergeConstructions(target = [], source = []) {
      for (let construction of target) {
         if (source.find(item => item._id === construction._id)) {
            source = source.filter(item => item._id !== construction._id);
         }
      }
      return target.concat(source)
   }

   // submit khi người dùng click vào nút xác nhận ở tổng giờ làm
   submitTotalHour = (value) => {
      this.setState({
         totalTime: value
      })
   }
   checkErrorTotalHour = (error) => {
      this.errorTotalHour = error
   }
   //chỉ chấp nhận chữ số
   formatData = (value) => {
      return String(value).replace(/[^0-9]/g, "");
   }
   //lấy địa chỉ từ thằng google
   getPosition = async () => {
      return new Promise(r => {
         navigator.geolocation.getCurrentPosition((data) => {
            let lat = _.get(data.coords, "latitude", 35.4122)
            let lng = _.get(data.coords, "longitude", 139.4130)
            r({
               lat: lat,
               lng: lng
            })
         }, (error) => { alert(I18n.t("Exception.locationAlert")) }, { maximumAge: 60000, timeout: 10000, enableHighAccuracy: true })
      })
   }

   //submit lúc nó check in
   onCheckIn = async () =>  {
      let { initCheckTime = {} } = this.props
      let { constructions = [], driving_length_on_private, 
         driving_length_on_working, fuel_supply_amount, memo, _id } = initCheckTime
      let listConstruction = []
      if (constructions.length) {
         constructions.map((item) => {
            let array = {
               constructionId: item._id,
               workTime: 0
            }
            listConstruction.push(array)
         })
      }
      let position = {}
      if (process.env.NODE_ENV === 'development') {
         position.lat = 21.0012507
         position.lng = 105.7938183
      } else {
         try {
            position = await this.getPosition()
         } catch (error) {
         }
      }

      let data = {
         latitudeIn: position.lat,
         longitudeIn: position.lng,
         constructions: listConstruction,
         driving_length_on_working: driving_length_on_working === undefined ? driving_length_on_working : parseFloat(driving_length_on_working),
         driving_length_on_private: driving_length_on_private === undefined ? driving_length_on_private : parseFloat(driving_length_on_private),
         fuel_supply_amount: fuel_supply_amount === undefined ? fuel_supply_amount : parseFloat(fuel_supply_amount),
         memo: memo,
         _id: _id
      }
      return this.props.onCheckIn(data)
   }

   //chạy khi ấn vào nút check out, set total hour và show hours, minutes
   onCheckOut() {
      let { initCheckTime = {} } = this.props
      let { endTime } = initCheckTime
      if (!endTime) {
         this.props.setCheckoutTime()
      }
   }

   //convert time to milliseconds
   convertTimeToMs({ hours, minutes }) {
      return moment.duration(Number(hours), 'hours').asMilliseconds() +
         moment.duration(Number(minutes), 'minutes').asMilliseconds()
   }

   async onSubmit(data = {}) {
      let totalWork = 0
      data.constructions = data.constructions || []
      data.constructions.map((con, i) => {
         let workTime = this.convertTimeToMs({
            hours: con['hours'],
            minutes: con['minutes']
         })
         totalWork += workTime
         data.constructions[i]['workTime'] = workTime;
         data.constructions[i]['address'] = con['address'] ? con['address'] : ""
      })

      let position = {}
      if (process.env.NODE_ENV === 'development') {
         position.lat = 21.0012507
         position.lng = 105.7938183
      } else {
         try {
            position = await this.getPosition()
         } catch (error) {
         }
      }
      data = {
         ...data,
         totalWork: totalWork,
         latitudeOut: position.lat,
         longitudeOut: position.lng,
         totalTime: this.state.totalTime,
         endTime: this.endTime,
         errorTotalHour: this.errorTotalHour
      }
      return this.props.onCheckOut(data)
   }

   setLocalstorage(constructionId, today) {
      localStorage.setItem('constructionId', constructionId)
      localStorage.setItem('today', today)
      localStorage.setItem('createRequest', 'constructionRequest')
   }

   renderDialogConfirmDelete() {
      return <ConfirmDialog
         ref={(ref) => this.ConfirmDialog = ref}
         title={I18n.t('Message.deleteDialogTitle')}
         // content={I18n.t('Message.deleteDialogContent')}
         onSubmit={this.onDeleteConstruction}
      />
   }

   renderDialogConfirmCheckout() {
      return <ConfirmDialog
         ref={(ref) => this.ConfirmCheckout = ref}
         title={I18n.t('Message.checkoutConfirmTitle')}
         content={I18n.t('Message.checkoutConfirmContent')}
         onSubmit={this.onCheckOut}
      />
   }

   renderConstruction = (construction, index, today) => {
      const { classes, initCheckTime = {} } = this.props
      const { endTime } = initCheckTime
      let workTime = _.get(construction, 'workTime', 0);
      let { hours, minutes } = common.msToTime(workTime)
      let constructionCode = _.get(construction, 'code', null)
      let constructionName = _.get(construction, 'name', null)
      let constructionId = _.get(construction, '_id', null)
      let address = _.get(construction, 'address', null)
      let canNotDelete = _.get(construction, 'canNotDelete', false)

      return (
         <ExpansionPanel key={index} style={{ height: '100%' }} expanded={Boolean(endTime) && !Boolean(this.edit)}>
            <ExpansionPanelSummary
               className={classes.ExpansionSummary}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Grid container >
                  <Grid item xs={9}>
                     <Typography>
                        {I18n.t("Construction.code")}: { constructionCode && constructionCode !== null ? constructionCode.replace(/-/g, "") : constructionCode}<br />
                     </Typography>
                     <Typography>
                        {I18n.t("Construction.name")}: {constructionName}<br />
                     </Typography>
                     <Grid>
                        {
                           (canNotDelete || this.edit)
                           ?
                           <Typography>{I18n.t("Construction.address")}: {address}</Typography>
                           :
                           <TextField
                              fullWidth
                              onChange={() => {}}
                              label={I18n.t("Construction.address")}
                              name={`constructions[${index}][address]`}
                              value={address}
                           />
                        }                        
                     </Grid>
                  </Grid>
                  <Grid item xs={3}>
                     {(canNotDelete || this.edit)? '' : <DeleteIcon onClick={() => this.ConfirmDialog.show(constructionId)} style={{ top: '0', right: '0', position: 'absolute', color: 'red' }} />}
                     <br />
                     <Link
                        to={{ pathname: '/workers/requests/create' }} style={{ textDecoration: 'none' }}>
                        <Button
                           onClick={() => this.setLocalstorage(constructionId, today)}
                           variant='contained'
                           color='primary'
                           className={classes.fixButton}
                           style={{ marginRight: '5px' }}>
                           {I18n.t("Button.detail")}
                        </Button>
                     </Link>
                  </Grid>
                  <TextField
                     type="hidden"
                     name={`constructions[${index}][constructionId]`}
                     margin="normal"
                     value={constructionId}
                  />
               </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
               <Grid container direction="row" justify="center" alignItems="center" spacing={8}>
                  <Grid item xs={6}>
                     <TextField
                        fullWidth
                        label={I18n.t("Input.hours")}
                        name={`constructions[${index}][hours]`}
                        index={index}
                        formatData={this.formatData}
                        margin="normal"
                        type="tel"
                        value={`${hours}`}
                        validate={this.validate.hours}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <TextField
                        fullWidth
                        label={I18n.t("Input.minutes")}
                        type="tel"
                        name={`constructions[${index}][minutes]`}
                        margin="normal"
                        formatData={this.formatData}
                        value={`${minutes}`}
                        validate={this.validate.minutes}
                     />
                  </Grid>
               </Grid>
            </ExpansionPanelDetails>
         </ExpansionPanel>
      )
   }

   onClickEdit = () => {
      this.edit = false
      this.setState({ reload: !this.state.reload })
   }

   numberToDay = (day) => {
      let wordDay = ['日', '月', '火', '水', '木', '金', '土']
      return wordDay[day]
   }

   componentDidUpdate(prevProps) {
      let heightWorkingTime = document.getElementsByClassName('working-time')[0].clientHeight
      let heightDriverLog = document.getElementsByClassName('update-driver-log')[0].clientHeight
      let checkMarginTopSlide = window.innerHeight - heightWorkingTime - heightDriverLog - 250

      if(checkMarginTopSlide > 0) {
         this.setState({
            marginTopSlide: checkMarginTopSlide
         })
      } else {
         this.setState({
            marginTopSlide: 20
         })
      }

      let checkMarginTopDriverLog = window.innerHeight - heightWorkingTime - 450
      if(checkMarginTopDriverLog > 0) {
         this.setState({
            marginTopDriverLog: checkMarginTopDriverLog
         })
      } else {
         this.setState({
            marginTopDriverLog: 20
         })
      }

      if(this.props.historyFlagSlide !== undefined) {
         this.setState({totalTime: this.props.historyFlagSlide.totalTime})
      }

      if(prevProps.initCheckTime !== this.props.initCheckTime) {
         let { initCheckTime = {} } = this.props
         this.setState({
            driving_length_on_working: initCheckTime.driving_length_on_working !== undefined ? initCheckTime.driving_length_on_working : null,
            driving_length_on_private: initCheckTime.driving_length_on_private !== undefined ? initCheckTime.driving_length_on_private : null,
            fuel_supply_amount: initCheckTime.fuel_supply_amount !== undefined ? initCheckTime.fuel_supply_amount : null,
            memo: initCheckTime.memo !== undefined ? initCheckTime.memo : null,
            alcohol: initCheckTime.alcohol
         })
      }
   }

   componentDidMount() {
      localStorage.setItem('userCalendar', JSON.parse(localStorage.user)._id)
      localStorage.setItem('dateCalendar', new Date())
   }

   getConstructionDropDown () {
      let { initCheckTime = {} } = this.props
      let { constructionDropdown = [], slideTime } = initCheckTime
      // if(slideTime == null) return constructionDropdown
      // return constructionDropdown.filter(item  => item.slideTime === slideTime)
      return constructionDropdown
   }

   onDeleteConstruction = (id) => {
      if (!id) return
      let { initCheckTime = {} } = this.props
      let { constructions = [] } = initCheckTime
      constructions = constructions.filter(item => item._id !== id)
      this.props.updateConstructions(constructions)
   }

   onAddConstruction = (value) => {
      let constructionInput = _.get(value, 'value', '')
      this.setState({ constructionInput: constructionInput})
      this.setState({ reload: !this.state.reload})
      let { initCheckTime = {} } = this.props
      let { constructionDropdown = [], constructions = [] } = initCheckTime
      if (!value) return
      let exist = constructions.find(item => item._id === value.value)
      if (exist) return
      let construction = constructionDropdown.find(item => item._id === value.value)
      if(!construction) return
      constructions.push(construction)
      
      this.props.updateConstructions(constructions)
      // this.setState({ reload: !this.state.reload })
      let constructionId = _.get(value, 'value', '')
      let today = moment(new Date()).format('YYYY/MM/DD')
      this.setLocalstorage(constructionId, today)
   }

   toggleEditCheckOut() {
      this.setState({
         editingCheckOut: !this.state.editingCheckOut
      })
   }

   toggleEditCheckIn() {
      this.setState({
         editingCheckIn: !this.state.editingCheckIn
      })
   }

   onUpdateCheckingTime(field) {
      let slideWorking = localStorage.getItem('slideWorking') === 'true' ? true : false
      let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
      const { checking } = this.state
      if (!checking) {
         this.setState({
            editingCheckIn: false,
            editingCheckOut: false
         })
      } else {
         this.props.onUpdateCheckingTime({name: field, value: checking[field], slideWorking: slideWorking, checkTimeZoneFE: checkTimeZoneFE}, () => {
            this.setState({
               editingCheckIn: false,
               editingCheckOut: false
            })
         })
      }
   }

   onSubmitDriverLog = (data) => {
      let driving_length_on_working = null, driving_length_on_private = null , fuel_supply_amount = null , memo = ''
      if(data.driving_length_on_working){ driving_length_on_working = data.driving_length_on_working }
      if(data.driving_length_on_private) { driving_length_on_private = data.driving_length_on_private }
      if(data.fuel_supply_amount) { fuel_supply_amount = data.fuel_supply_amount }
      if(data.memo) { memo = data.memo.toString() }

      let dataSubmit = {
         date: data.date,
         idRequest: data.idRequest,
         driving_length_on_working: driving_length_on_working,
         driving_length_on_private: driving_length_on_private,
         fuel_supply_amount: fuel_supply_amount,
         memo: memo
     }

      this.props.onUpdateHistory(dataSubmit)
   }

   onChangeCheckingTime = (name, key, value) => {
      let { initCheckTime = {} } = this.props
      let { setting } = initCheckTime

      let hoursStart = 0, minutesStart = 0
      if(setting) {
         hoursStart = parseInt(moment(setting.overTimeNightEnd).format('HH'))
         minutesStart = parseInt(moment(setting.overTimeNightEnd).format('mm'))
      }

      if( name == 'startTime' && key == 'hour') {
         if(value > hoursStart || this.state.slideWorking) minutesStart = 0

         this.setState({
            minutesStart:[
               Validation.min(minutesStart, `最小値が ${minutesStart} で入力してください`),
               Validation.max(60, I18n.t("Form.max")),
            ]
         })
      }

      let checking = this.state.checking || {startTime: this.props.initCheckTime.startTime, endTime: this.props.initCheckTime.endTime}
      checking[name] = moment(checking[name]).set({[key]: value}).format()
      this.setState({checking: checking})
   }

   changeSlideWorking() {
      let stateSlideWorking = !this.state.slideWorking

      this.setState({
         slideWorking: stateSlideWorking
      })
      // localStorage.removeItem("slideWorking");
      localStorage.setItem('slideWorking', stateSlideWorking)

      if(this.props.initCheckTime && this.props.initCheckTime._id) {
         this.props.onUpdateFlagWorking({slideWorking: stateSlideWorking, historyId: this.props.initCheckTime._id})
      }
   }

   changeAlcohol() {
      if(this.props.initCheckTime && this.props.initCheckTime._id) {
         this.props.onUpdateAlcohol({alcohol: this.state.alcohol, historyId: this.props.initCheckTime._id})
      }
   }

   renderTotalHour() {
      return (
         <TotalHour
            submitTotalHour={this.submitTotalHour}
            convertTimeToMs={this.convertTimeToMs}
            // msToTime={this.msToTime}
            // totalTime={this.state.totalTimeInstruction}
            totalTime={this.state.totalTime}
            checkErrorTotalHour={this.checkErrorTotalHour}
         />
      )
   }
   
   renderConstructionDropdown() {
      let { initCheckTime = {} } = this.props
      let { constructionDropdown = [] } = initCheckTime
      let construcrions = this.getConstructionDropDown() || []

      let arrConstructionCode = []
      let arrConstructionName = []
      construcrions.map(item => {
         arrConstructionCode.push({label: item.code, value: item._id})
         arrConstructionName.push({label: item.name, value: item._id})
      })

      return (
         <Grid container spacing={16}>
            <Grid item xs={6} md={6}>
               <Grid container spacing={16}>
               <Grid item xs={12} md={12}>
               <Select 
                  style={{marginTop: '16px'}}
                  key="2"
                  isClearable
                  backspaceRemovesValue
                  components={{ OptionList }} 
                  options={arrConstructionCode} 
                  styles={customStyles}
                  placeholder={I18n.t("Table.header.construction.code")}
                  onChange={this.onAddConstruction}
                  onInputChange={(inputValue) => {
                        return inputValue.replace(/[^0-9]/g, "");
                  }}
               />
            </Grid>
            <Grid item xs={12} md={12}>
               <Select 
                  style={{marginTop: '16px'}}
                  key="2"
                  isClearable
                  backspaceRemovesValue
                  components={{ OptionList }} 
                  options={arrConstructionName} 
                  styles={customStyles}
                  placeholder={I18n.t("Table.header.construction.name")}
                  onChange={this.onAddConstruction}
               />
            </Grid>
               </Grid>
            </Grid>
            
            <Grid item xs={6} md={6}>
               <TextField     
                  fullWidth     
                  validate={this.validate.alcohol}
                  label={`${I18n.t('Label.alcohol')}`}
                  value={this.state.alcohol}
                  name="alcohol"
                  onChange={(value) => {
                     this.setState({alcohol: value.replace(',', '.')})
                   }}
                  onBlur={() => {
                     const { alcohol } = this.state;
                     const checkValueUpdate = parseFloat(alcohol);

                     if (alcohol && checkValueUpdate >= 0 && checkValueUpdate <= 1  && /^\d*\.\d*$/.test(alcohol)) {
                        this.changeAlcohol('alcohol', alcohol);
                     }
                  }}
               />
            </Grid>
         </Grid>
      )
   }

   updateValidate(minutesStart, hoursStart){
      this.state.hoursStart = [
         Validation.min(hoursStart, `最小値が ${hoursStart} で入力してください。`),
         Validation.max(24, I18n.t("Form.max")),
      ]

      this.state.minutesStart = [
               Validation.min(minutesStart, `最小値が ${minutesStart} で入力してください。`),
               Validation.max(60, I18n.t("Form.max")),
            ]
   }

   onCancelDriverLog = () => {      
      let { initCheckTime = {} } = this.props
      this.setState({
         driving_length_on_working: initCheckTime.driving_length_on_working !== null ? initCheckTime.driving_length_on_working : "",
         fuel_supply_amount: initCheckTime.fuel_supply_amount !== null ? initCheckTime.fuel_supply_amount : "",
         driving_length_on_private: initCheckTime.driving_length_on_private !== null ? initCheckTime.driving_length_on_private : "",
         memo: initCheckTime.memo !== null ? initCheckTime.memo : 0,
         reload: true
      })
   }

   onChangeDriverLog = (name, value) => {
      switch(name){
         case 'driving_length_on_working':
            this.setState({driving_length_on_working: value, reload: true})
            return
         case 'fuel_supply_amount':
            this.setState({fuel_supply_amount: value, reload: true})
            return
         case 'driving_length_on_private': 
            this.setState({driving_length_on_private: value, reload: true})
            return
         case 'memo':
            this.setState({memo: value, reload: true})
            return
         default:
            return
      }
   }

   renderDriverLog = (_id, classes, date) => {
      let inputStyle,gridStyle,cancelStyle,style797
      if (window.innerWidth<500) {
         gridStyle = {
            marginTop: '160px',
            paddingLeft:'0px'
         }
         cancelStyle ={
            marginLeft: '0px',
            alignItems: 'center',
            paddingLeft: '0px',
            paddingRight:'0px',
            fontSize:'0.6rem'
         }
         inputStyle = {
            paddingLeft: '0px',
            paddingRight: '0px',
            fontSize:'0.6rem',
            marginBottom:'10px'
         }
         style797 = {
            width:'230px',
            paddingRight:'0px',
         }
      }
      else {
         gridStyle = {
            marginTop: '14px'
         }
         cancelStyle ={
            marginLeft: '5px', 
            alignItems: 'center'
         }
      }

      return (
            <Grid id='756' container spacing={32} className={`${classes.SlideReportArea}`} 
              style={{marginTop: `${this.state.marginTopDriverLog}px`, width: 'inherit', marginLeft: 'inherit', marginRight: 'inherit'}} >
                  <TextField type="hidden" name="idRequest" defaultValue={_id ? _id : ''}/>
                  <TextField type="hidden" name="date" defaultValue={date}/>
                  <Grid item xs={9} lg={9} sm={6} style ={style797}>
                        <TextField                           
                           label={`${I18n.t('Calendar.driving_length_on_working')} (Km)`}
                           type="number"
                           inputProps={{step: 'any'}}
                           style={{alignItems: 'center', marginTop: 0, marginBottom: '16px !important', marginRight: '12px' }}
                           value={this.state.driving_length_on_working}
                           name="driving_length_on_working"
                           onChange={value => this.onChangeDriverLog('driving_length_on_working', value)}
                        />
                        
                        <TextField
                           label={`${I18n.t('Calendar.fuel_supply_amount')} (ℓ)`}
                           type="number"
                           inputProps={{step: 'any'}}
                           style={{alignItems: 'center', marginTop: 0, marginBottom: '16px !important', marginRight: '12px' }}
                           value={this.state.fuel_supply_amount}
                           name="fuel_supply_amount"
                           onChange={value => this.onChangeDriverLog('fuel_supply_amount', value)}
                        />
                        
                        <TextField
                           label={`${I18n.t('Calendar.driving_length_on_private')} (Km)`}
                           type="number"
                           inputProps={{step: 'any'}}
                           style={{alignItems: 'center', marginTop: 0, marginBottom: '16px !important', marginRight: '12px' }}
                           value={this.state.driving_length_on_private}
                           name="driving_length_on_private"
                           onChange={value => this.onChangeDriverLog('driving_length_on_private', value)}
                        />
                        
                        <TextField
                           label={I18n.t('Calendar.memo')}
                           type="text"
                           value={this.state.memo}
                           name="memo"
                           style={{alignItems: 'center', marginTop: 0, marginBottom: '16px !important', marginRight: '12px' }}
                           onChange={value => this.onChangeDriverLog('memo', value)}
                        /> 
                     </Grid>
                     <Grid id='group_button' item xs={3} lg={3} sg={6} 
                        style={gridStyle}

                        >
                        <Button
                           variant="outlined" 
                           type='submit'
          
                           style ={inputStyle}
                           >
                           {I18n.t("Button.register")}
                        </Button>
                        <Button
                           id  ='cancel'
                           style ={cancelStyle}
                           variant="outlined" 
                           onClick={this.onCancelDriverLog}
                        >
                           {I18n.t("Button.cancel")}
                        </Button> 
                     </Grid>
                  </Grid>
      )
   }

   render() {
      let { classes, initCheckTime = {}, flagChecked } = this.props
      let { _id, date, startTime, endTime, checkOutStatus, checkInStatus, constructions = [], setting } = initCheckTime
      localStorage.setItem('dateRequest', date)
      localStorage.setItem('back', 'workers')
      let checking = this.state.checking || { startTime: startTime, endTime: endTime }
      this.state.totalTimeInstruction = common.getOnlyHoursMinutes(endTime) - common.getOnlyHoursMinutes(startTime)

      if(setting && setting.overTimeBeforeStart) {
         let hoursStart = parseInt(moment(setting.overTimeBeforeStart).format('HH'))
         let minutesStart = parseInt(moment(setting.overTimeBeforeStart).format('mm'))
         if(parseInt(moment(checking.startTime).format('HH')) > hoursStart) minutesStart = 0

         if(this.state.slideWorking) {
            minutesStart = 0
            hoursStart = 0
         }
         this.updateValidate(minutesStart, hoursStart)
      }

      return (
         <Grid>
            <Grid className='working-time'>
               <PaperFade
                  className={
                     this.state.slideWorking
                     ?
                     `${classes.paper} ${classes.BackGroudSlideTime}`
                     :
                     classes.paper
                  }
               >
                  <Grid container spacing={32}>
                     <Grid item xs={12}>
                        <Typography variant="h5" component="h5" style={{ textAlign: "center" }}>
                           <span>{moment(date).format('YYYY')}  </span>
                           <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{moment(date).format('MM.DD')}</span>
                           <span>  ({this.numberToDay(moment(date).day())})</span>
                        </Typography>
                     </Grid>
                  </Grid>

                  <Grid container spacing={16} className={classes.GroupBtn}>
                     <Grid item xs={12} lg={6} className='checkin-grid'>
                        <Button
                           id="CheckInBtn"
                           disabled={checkInStatus || flagChecked}
                           className={`${classes.Button} ${classes.CheckInBtn}`}
                           type="button"
                           onClick={this.onCheckIn}
                           variant="contained"
                           fullWidth>
                           <img 
                              src={process.env.PUBLIC_URL + '/button/checkout.png'} 
                              alt="checkin"
                              style={{ width: '60px', height: '65px' }}
                           />
                           <br />
                           <span className={classes.spanCheck} style={{ fontSize: '33px', color: '#030003' }}>{I18n.t("Calendar.checkIn")} </span>  
                           <span className={classes.spanCheck} style={{ fontSize: '25px', color: '#030003' }}>{startTime ? moment(startTime).format('HH: mm') : ""}</span>
                        </Button>
                        {startTime &&
                           <>
                              <EditIcon onClick={this.toggleEditCheckIn.bind(this)}/>

                              <Dialog
                                 open={this.state.editingCheckIn || false}
                                 onClose={this.onCancel}
                                 aria-labelledby="alert-dialog-title"
                                 aria-describedby="alert-dialog-description"
                                 maxWidth="sm"
                              >
                                 <Form className={classes.form} onSubmit={() => this.onUpdateCheckingTime('startTime')}>
                                    <DialogTitle>
                                       {I18n.t('Worker.editCheckIn')}
                                    </DialogTitle>
                                    <DialogContent>
                                       <Grid container direction="row" justify="center" alignItems="center" spacing={8}>
                                          <Grid item xs={12} lg={6}>
                                             <TextField
                                                fullWidth
                                                label={I18n.t("Common.hour")}
                                                value={checking.startTime ? moment(checking.startTime).format('H') : ""}
                                                name='startTimeHour'
                                                onChange={value => this.onChangeCheckingTime('startTime', 'hour', value)}
                                                validate={this.state.hoursStart}
                                          />
                                          </Grid>
                                          <Grid item xs={12} lg={6}>
                                             <TextField
                                                fullWidth
                                                value={checking.startTime ? moment(checking.startTime).format('m') : ""}
                                                label={I18n.t("Common.minute")}
                                                name='startTimeMinute'
                                                validate={this.state.minutesStart}
                                                onChange={value => this.onChangeCheckingTime('startTime', 'minute', value)}
                                          />
                                          </Grid>
                                       </Grid>
                                    </DialogContent>
                                    <DialogActions >
                                       <Button color="primary" type="submit">
                                          {I18n.t("Button.submit")}
                                       </Button>
                                       <Button color="primary" onClick={this.toggleEditCheckIn.bind(this)} >
                                          {I18n.t("Button.cancel")}
                                       </Button>
                                    </DialogActions>
                                 </Form>
                           </Dialog>
                           </>
                        }
                     </Grid>

                     <Grid item xs={12} lg={6} className='checkout-grid'>
                        <Button
                           disabled={!checkInStatus || Boolean(endTime)}
                           id="CheckOutBtn"
                           className={`${classes.Button} ${classes.CheckOutBtn}`}
                           onClick={() => this.ConfirmCheckout.show()}
                           variant="contained"
                           fullWidth>
                           <img 
                              src={process.env.PUBLIC_URL + '/button/checkin.png'} 
                              alt="checkout"
                              style={{ width: '60px', height: '65px' }}
                           />
                           <br />
                           <span className={classes.spanCheck} style={{ fontSize: '33px', color: '#030003' }}>{I18n.t("Calendar.checkOut")} </span>  
                           <span className={classes.spanCheck} style={{ fontSize: '25px', color: '#030003' }}>{endTime ? moment(endTime).format('HH: mm') : ""}</span>
                        </Button>
                        {endTime &&
                           <>
                              <EditIcon onClick={this.toggleEditCheckOut.bind(this, 'endTime')} />
                              <Dialog
                                 open={this.state.editingCheckOut || false}
                                 onClose={this.onCancel}
                                 aria-labelledby="alert-dialog-title"
                                 aria-describedby="alert-dialog-description"
                                 maxWidth="lg"
                           >
                                 <Form className={classes.form} onSubmit={() => this.onUpdateCheckingTime('endTime')}>
                                    <DialogTitle>
                                       {I18n.t('Worker.editCheckOut')}
                                    </DialogTitle>
                                    <DialogContent>
                                       <Grid container direction="row" justify="center" alignItems="center" spacing={8}>
                                          <Grid item xs={12} lg={6}>
                                             <TextField
                                                fullWidth
                                                label={I18n.t("Common.hour")}
                                                value={checking.endTime ? moment(checking.endTime).format('H') : ""}
                                                name='endTimeHour'
                                                onChange={value => this.onChangeCheckingTime('endTime', 'hour', value)}
                                                validate={this.validate.hours}
                                                type="tel"
                                          />
                                          </Grid>
                                          <Grid item xs={12} lg={6}>
                                             <TextField
                                                fullWidth
                                                value={checking.endTime ? moment(checking.endTime).format('m') : ""}
                                                label={I18n.t("Common.minute")}
                                                name='endTimeMinute'
                                                validate={this.validate.minutes}
                                                onChange={value => this.onChangeCheckingTime('endTime', 'minute', value)}
                                                type="tel"
                                          />
                                          </Grid>
                                       </Grid>
                                    </DialogContent>
                                    <DialogActions >
                                       <Button color="primary" type="submit">
                                          {I18n.t("Button.submit")}
                                       </Button>
                                       <Button color="primary" onClick={this.toggleEditCheckOut.bind(this)} >
                                          {I18n.t("Button.cancel")}
                                       </Button>
                                    </DialogActions>
                                 </Form>
                           </Dialog>
                           </>
                        }
                     </Grid>
                  </Grid>
                  {
                     checkInStatus ? this.renderConstructionDropdown() : ""
                  }
                  {
                     endTime ? this.renderTotalHour() : ""
                  }

                  <Form className={classes.form} onSubmit={this.onSubmit}>
                     <Grid container alignItems="stretch" spacing={16}>
                        {
                           constructions.map((construction, index) => {
                              return <Grid item xs={12} md={6} key={index}>{this.renderConstruction(construction, index, date)}</Grid>
                           })
                        }
                     </Grid>
                     <CardActions>
                        {
                           !Boolean(this.edit) && endTime ?
                              <React.Fragment>
                                 <Button
                                    className={classes.SubmitBtn}
                                    type="submit"
                                    disabled = {!Boolean(constructions.length)}
                                    variant="contained"
                                    color="primary">
                                    {I18n.t("Button.submit")}
                                 </Button>

                                 <Link
                                    className={classes.SubmitBtn}
                                    to={{ pathname: '/workers/reports/create' }} 
                                    color="blue"
                                    style={{ textDecoration: 'none', marginLeft: '1rem', pointerEvents: Boolean(constructions.length) ? 'all' : 'none' }}
                                 >
                                    <Button
                                       disabled = {!Boolean(constructions.length)}
                                       variant='contained'
                                       color='primary'
                                       type='button'>
                                       {I18n.t("Calendar.report")}
                                    </Button>
                                 </Link>
                              </React.Fragment>
                              :
                              ""
                        }
                        {
                           Boolean(this.edit)
                              ? <Button
                                 className={classes.EditBtn}
                                 variant="contained"
                                 color="primary"
                                 onClick={this.onClickEdit}>
                                 {I18n.t("Button.edit")}
                              </Button>
                              :
                              ""
                        }
                     </CardActions>
                  </Form>
                  {this.renderDialogConfirmDelete()}
                  {this.renderDialogConfirmCheckout()}
               </PaperFade>
            </Grid>

             <Form className={`update-driver-log`} onSubmit={(item) => this.onSubmitDriverLog(item)}>
               <PaperFade>
               {this.renderDriverLog(_id, classes, moment(date).format('YYYY-MM-DD'))}
               </PaperFade>
            </Form> 

            <Grid container spacing={16} className={`${classes.SlideReportArea}`} style={{marginTop: `${this.state.marginTopSlide}px`, width: 'inherit', marginLeft: 'inherit', marginRight: 'inherit'}} >
               <Grid item xs={6} lg={6} style={{ bottom: '10px', paddingLeft: '24px'}}>
                  <Button
                     disabled= { checkOutStatus }
                     className={
                        this.state.slideWorking
                        ?
                        `${classes.ButtonSmall} ${classes.SlideTrueBtn}`
                        :
                        `${classes.ButtonSmall} ${classes.BaseBtn}`
                     }
                     onClick={this.changeSlideWorking}
                     variant="contained"
                     fullWidth>
                     <img 
                        src={process.env.PUBLIC_URL + '/button/slide-working.png'} 
                        alt="slide working"
                        style={{ width: '40px', height: '25px' }}
                     />
                     <br />
                     <span className={`${classes.spanText}`} style={{ fontSize: '21px', color: '#030003' }}>
                        {I18n.t("Calendar.slideWorking")}
                     </span>
                  </Button>
               </Grid>

               <Grid item xs={6} lg={6} style={{ bottom: '10px', paddingRight: '24px'}}>
                  <Link
                     to={{ pathname: '/reports/create' }} style={{ textDecoration: 'none' }}>
                     <Button
                        className={`${classes.ButtonSmall} ${classes.BaseBtn}`}
                        onClick={() => this.ConfirmCheckout.show()}
                        variant="contained"
                        fullWidth>
                        <img 
                           src={process.env.PUBLIC_URL + '/button/report.png'} 
                           alt="slide working"
                           style={{ width: '40px', height: '35px' }}
                        />
                        <br />
                        <span className={`${classes.spanText}`} style={{ fontSize: '21px', color: '#030003' }}>
                           {I18n.t("Button.report")} 
                        </span>
                     </Button>
                  </Link>
               </Grid>
            </Grid>
         </Grid>
      )
   }
}

Index.propTypes = {
   classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(Index))
