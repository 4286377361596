export default {
    LogOut: {
        title: 'ログアウト',
        contentText: 'ログアウトしますが、よろしいでしょうか？',
        agree: 'はい',
        disagree: 'いいえ'
    },
    toolTip: {
      print: "印刷",
      new: "追加",
      csv: "CSVへ出力",
      excel: "Excelへ出力",
      pdf: "PDFへ出力",
      export: "出力",
      delete: "削除",
      edit: "編集",
    //   sort: "並べ替え",
    //   access: "Khả năng truy cập",
      import: "Excelから入力"

    },
    DateTimeField: {
        cancel: "キャンセル",
        locale: "ja",
        AM: "午前",
        PM: "午後",
        year: "年"
    },
    NotFound: {
        title: "お探しのページは見つかりませんでした。"
    },
    Form: {
        codeExcelErr: '工事現場コードのフォーマットが正しくありません。',
        noOption: "選択肢がありません",
        buttonOK: 'OK',
        buttonClear: '削除',
        checkLocale: "ja",
        required: 'この項目は必須項目です。',
        min: '最小値が ${arguments[0]} で入力してください。',
        max: '最大値が ${arguments[0]} で入力してください。',
        minLength: '最小 ${arguments[0]} 桁を入力してください。',
        maxLength: '最大 ${arguments[0]} 桁を入力してください。',
        lessThan: '${arguments[0]}以下入力してください。',
        greatherThan: '${arguments[0]}以上入力してください。',
        incorrectPassword: 'IDまたはパスワードが間違っています。アカウントの情報を忘れた場合、管理者に連絡してください。',
        duplicateDate: 'この日時は既に存在しています。別の日時を選んでください。',
        pastDate: '過去の日は選択できません。別の日を選択してください。',
        User: {
            userName: '6桁から24桁までの文字列（空白を含まない）を入力してください。',
            password: '18桁以内に（空白を含まない）入力してください。',
            code: '1桁から15桁までの文字列（空白桁を含まない）を入力してください。',
            name: '1桁から50桁までの文字列を入力してください。',
            min2: '1桁以上入力してください。',
            maxLength15: '15桁以内に数字を入力してください',
            phone: '1桁から11桁までの数字列を入力してください。',
            number: '20より小さい値を入力してください。',
            leng_1_255: '0桁から255桁までの文字列（空白を含まない）を入力してください。',
            leng_255: '文字列の長さ'
        },
        Role: {
            name: '1桁から50桁までの文字列を入力してください。'
        },
        Position: {
            code: '1桁から15桁までの文字列（空白を含まない）を入力してください。',
            name: '1桁から50桁までの文字列を入力してください。',
            remuneration: '1桁から10桁までの数字列を入力してください。'
        },
        Area: {
            code: '1桁から15桁までの文字列（空白を含まない）を入力してください。',
            name: '50桁以内に入力してください'
        },
        Construction: {
            code: '1桁から8桁までの文字列（空白を含まない）を入力してください。',
            name: '100桁以内に入力してください ',
            address: '100桁以内に入力してください',
            amount: '1桁から12桁までの数字列を入力してください。',
            cost: '1桁から12桁までの数字列を入力してください。',
            number: '１桁から12桁までの数字列を入力してください。'
        },
        Report: {
            Title: '100桁以内に入力してください',
            Contractor: '100桁以内に入力してください',
            Content: '1000桁以内に入力してください',
            Construction_unit: '100桁以内に入力してください',
            Problem_report: '2000桁以内に入力してください'
        },
        Request: {
            Content: '1000桁以内に入力してください',
            Hour: '時間は24時間以内の値を入力してください。',
            Minute: '分数は60以内の値を入力してください。'
        },
        History: {
            endTime: '退勤日は出勤日以降にしなければなりません。'
        },
        CheckInOut: {
            number: '数字のみを入力してください。',
            checkTime: '入力した労働時間は勤怠実績の時間を数を超えています。再度確認してください!',
            totalHour: '　労働時間を確定してください。',
            maxTotal: '振り分けした時間の合計はシステムに記録された時間を超えられません。',      
            splitTime: "少なくとも1つの工事現場に労務時間を振り分ける必要があります。"
        },
        lessHour: '退勤時間は出勤時間の以降にしなければなりません。',
        userName: '6桁から16桁までの文字や数字を両方入力してください。',
        noSpace: '空白を入力しないでください!',
        hours: '時間は24時間以内の値を入力してください。',
        hoursRequest: '時間は8時間以内の値を入力してください。',
        notValidDate: '日付のフォーマットが不正です。',
        minutes: '分数は60以内の値を入力してください。',
        maxLeng1000: '1000桁以内に入力してください',
        maxLeng255: '255桁以内に入力してください',
        mangleng11: '11桁以内に入力してください',
        mangleng20: '20桁以内に入力してください',
        max20: '20より小さい値を入力してください。',
        max60: '60より小さい値を入力してください。',
        max180: '180より小さい値を入力してください。',
        number: '数字のみ入力してください。',
        lengPhone: '11桁の数字以内に入力してください',
        lengMoney: '10桁の数字以内に入力してください'
    },
    Common: {
        start: '開始',
        end: '終了',
        from: 'から',
        to: 'まで',
        ceil: '切り上げ',
        floor: '切り捨て',
        minute: '分',
        monday: '月',
        tuesday: '火',
        wednesday: '水',
        thursday: '木',
        friday: '金',
        saturday: '土',
        sunday: '日',
        time: '時間',
        hour: '時'
    },
    Input: {
        engineeringNumber: '工番',
        reason: '理由',
        alternativeScheduledDate: '代替予定日',
        typeTime: '勤務時間設定',
        hourType: '勤務時間表記の方法',
        month: "月",
        username: 'ユーザーID',
        name: '従業員名',
        userCode: '社員コード',
        manage: '認証者',
        constructionCode: '現場コード',
        constructionName: '現場名',
        account: 'ユーザーID',
        filter: '絞り込み',
        phonenumber: '電話番号',
        group: 'グループ',
        area: {
            code: 'エリアコード',
            name: 'エリア区分'
        },
        password: 'パスワード',
        rePassword: 'パスワードをもう一度入力してください。',
        amount: '金額',
        date: '日時',
        dateText: '日時',
        code: '社員コード',
        groupCode: 'エリアコード',
        groupName: 'エリアの名称',
        title: '件名',
        calendarUser: '月報入力カレンダー:',
        content: '内容',
        construction_unit: '施工班',
        problem_report: '連絡事項',
        classify: '区分',
        user: {
            username: 'ユーザーID',
            code: '社員コード',
            name: '従業員名',
            phone: '電話番号',
            position: '役職',
            group: 'グループ',
            area: 'エリア',
            joining_date: '入社日',
            password: 'パスワード',
            rePassword: 'パスワードをもう一度入力してください。',
            role: '役割',
            remainLastYear: '前々年',
            remainThisYear: '前年',
            remainTotal: '合計',
            dayOff: '有給繰越日数',
            car_number: '車番号',
            resign: '退職'
        },
        role: {
            name: '役割',
            permission: 'アクセス許可'
        },
        position: {
            code: '役職コード',
            name: '役職',
            remuneration: '労務費'
        },
        construction: {
            formatContent: 'Format báo cáo', //
            info: '工事情報',
            date: '日時',
            code: '工事コード',
            name: '工事名',
            address: '住所',
            amount: '請負金額',
            cost: '工事予算',
            profit: '利益率',
            contractors: '元請',
            content: '工事内容',
            manage: '工事管理',
            titleContent: '作業報告フォーマット',
            formatContent: '内容',
            time: '時間',
            slideTime: 'スライド勤務',
            typeRequests: '申請種別',
            workNight: '勤務時間変更（夜勤）'
        },
        request: {
            type: '申請種別',
            type_1: '早朝残業',
            typeEarlyLate: '早上がり',
            formTypeEarlyLate: '早退',
            typeLate: '遅刻',
            typeOT: '残業申請',
            typeSalary: '有給申請',
            // typeSalary: '有休残',
            typeSalaryListRequest: '有給申請',
            typeSalaryCalendar: '有休',
            typeWorkOnDayOff: "休日出勤申請",
            // typeCompensated: '代休',
            typeCompensatedCalendar: '代休',
            typeCompensated: '代休残',
            typeCompensatedListRequest: '有休',
            typeReplace: '振替休日出勤',
            typeHoliday: '休暇申請',
            type_2: '早退',
            workingHoliday: '休日出勤',
            dayOffSalary: '有給休暇',
            holidaysInstead: '代休',
            hourLeft: "時間",
            dayLeft: "日",
            absent: "欠勤",
            dayoff: "特別休暇",
            onUsedCompensated: '使用する休日出勤分',
            status: {
                request: '承認中',
                approve: '承認済',
                reject: '非承認'
            }
        },
        roundStart: '開始',
        roundEnd: '終了',
        roundType: '丸め',
        selectWorkingDayInWeek: '労働日',
        holidayInProvision: '法定内休日',
        holidayOutProvision: '法定外休日',
        holidayName: '祝日',
        holidayType: '休日の種類',
        notProfit: '利益率を閲覧する権限がありません。',
        errorCheckOut: '退勤時間は出勤時間以降にしなければなりません。',
        dateStart: '工期始まり',
        dateEnd: '工期終わり',
        pickMonth: '月を選択',
        createFormatContent: '工事内容',
        listUser: '作業員リスト',
        subject: '件名',
        cost: '工事予算',
        costAmount: '利益率',
        warning: '緊急',
        manage1: '認証者 1',
        manage2: '認証者 2',
        newManage1: '承認者 1',
        newManage2: '承認者 2',
        manageCreate1: "承認者 1",
        manageCreate2: "承認者 2",
        address: '住所',
        contractor: '施工班',
        report: '工事内容',
        wokingDayOff: '振替出勤日',
        dateDayOff: '振替休日',
        dateRequest: '申請日',
        dateReplace: '振替休日',
        typeRequest: '申請種別',
        checkbox: '振替休日',
        chooseDate: '就業予定が同じ期間を選択します。',
        changePassword: {
            title: 'パスワード変更'
        },
        latLong: '座標',
        hours: '時',
        minutes: '分',
        Filter: '絞り込み',
        hourCheckIn: '出勤時間',
        totalTime: '労働時間の合計',
        hourCheckOut: '退勤時間',
        _date: '振替の出勤日',
        replaceDate: '振替の休日',
        compensatoryAvailable: '代休を取得できる月数'
    },
    Label: {
        hourSetting: "勤務時間表記",
        settingWorkingTime: '勤務時間設定',
        workingTime: '勤務時間',
        overTimeBefore: '早朝残業時間',
        overTime: '残業時間',
        overTimeNight: '深夜残業時間',
        nightTime: '深夜時間',
        settingRound: '丸め設定',
        settingHoliday: '祝日設定',
        updateHoliday: '休日編集',
        settingWeekHoliday: '毎週の休日設定',
        settingWorkDay: '営業日設定',
        calendar: '予定カレンダー',
        settingEmployeesForConstruction: '現場人員配置',
        settingEmployees: '作業員選択',
        Chat: 'チャット',
        chatHolder: " メッセージを入力する",
        failLine: 'LINEアカウントとリンクできませんでした！',
        successLine: 'LINEアカウントとリンクできました！',
        noonBreak: '休憩時間',
        early: '開始日',
        continuous: '終了日',
        compensatoryAvailable: '代休取得可能範囲',
        alcohol: 'アルコールチェック：検査数値',
    },
    Button: {
        addCreateConstruction: '工事追加',
        deleteData: '全データ削除',
        refreshExcel: "リフレッシュ",
        create: '追加',
        edit: '編集',
        delete: '削除',
        back: '戻る',
        formatContent: 'Format Nội dung', // 
        listContent: 'Tạo format', //
        createUser: '従業員作成',
        createGroup: '班作成',
        createArea: 'エリア作成',
        ok: 'OK',
        cancel: 'キャンセル',
        cancel2: '解除',
        report: '報告',
        search: '検索',
        logout: 'ログアウト',
        chose: '選択',
        createInput: '追加',
        createContainer: '工事内容入力',
        importFile: 'Excelからインポート',
        approved: '承認',
        unApproved: '非承認',
        addRequest: "申請追加",
        view: '既読',
        unview: '未読',
        request: '申請',
        listRequest: '申請一覧',
        unRequest: '再申請',
        detail: '詳細',
        submit: '確定',
        addDayOff: '休日追加',
        confirmAllow: 'この申請を承認しますが、よろしいでしょうか？',
        confirmSkip: 'この申請を却下しますがよろしいでしょうか？',
        changeConfirmAllow: '承認状態を変更しますが、よろしいでしょうか？',
        deleteConfirmAllow: '解除しますか？',
        deleteConfirmRemove: '削除しますか？',
        allow: '承認',
        allow_one: '認証者',
        allow_two: '認証者',
        skip: '非承認',
        skip_one: '非承認者',
        skip_two: '非承認者',
        line_account: 'LINEアカウント',
        changePassword: 'パスワード変更',
        listContent: 'フォーマット追加',
        round: '丸め有り',
        unRound: '丸め無し',
        register: '登録',
        preview: 'プレビュー',
        saveFourField: '運行情報登録',
        saveAll: "全て登録",
        showWorkingLog: "勤怠入力",
        hiddenReport: "非表示",
        requestPage: {
            userRequested: '申請済',
            approveOne: '第1承認者承認済',
            approveTwo: '第2承認済',
        }
    },
    Sidebar: {
        delete: '全データ削除', //
        user: 'ユーザー',
        group: '施工班',
        role: '役割',
        position: '役職',
        area: 'エリア',
        dayoff: '特別休暇',
        report: '作業報告',
        construction: '工事現場管理',
        labor: '労務管理',
        constructionHour: '実質労務時間管理', //
        history: '出勤履歴',
        worker: '出勤・退勤',
        request: '申請ツール',  // 申請申請
        settingTime: '基本設定',
        hour: '勤怠管理',
        calendar: '月報入力カレンダー',
        hourDetail: '勤怠確認',
        timekeeping: '個人別勤怠日計表',
        monthlyReport: '月報参照・申請・印刷',
        monthlyReportManagement: '月報一括参照・出力',
        calendarBooking: '機材予約カレンダー',
        listProduct: '機材一覧表',
        revenue: '機械損料管理表',
        printing: '機材管理者メニュー',
        damageNotDefine: '減損処理',
        assetMagement: {
            title: '機材管理'
        },
        workingLoad: {
            title: '工程管理',
            construction: '工程管理表－工事別', //'工程総合管理表',
            workload: '負荷管理表',//社員別担当案件確認表',
            worker_charges: '工程管理表－社員別', //'社員別工事スケジュール',
            team_managements: '工程管理表－施工班別', //施工班別工事スケジュール',
            estimate: '見積番号一覧表', //見積管理',
            load: '負荷マスタ',
            team: '施工班', //施工班マスタ',
        }
    },
    Breadcrumb: {
        deleteIndex: '全データ削除',
        constructionHourIndex: "実質労務時間管理",
        userIndex: 'ユーザーリスト',
        userCreate: 'ユーザー作成',
        userEdit: 'ユーザー情報編集',
        groupCreate: '班作成',
        roleIndex: '役割',
        roleCreate: '役割作成',
        roleEdit: '役割編集',
        positionIndex: '役職',
        positionCreate: '役職作成',
        positionEdit: '役職編集',
        areaIndex: 'エリア',
        areaCreate: 'エリア作成',
        areaEdit: 'エリア編集',
        settingTime: '基本設定',
        dayoffIndex: '特別休暇管理',
        dayoffCreate: '追加',
        dayoffEdit: '編集',
        reportIndex: '報告一覧',
        reportCreate: '報告作成',
        reportEdit: '報告編集',
        constructionIndex: '工事現場一覧',
        constructionCreate: '新規登録',
        constructionEdit: '工事現場編集',
        laborIndex: '労務管理一覧',
        laborDetail: '労務管理詳細',
        historyIndex: '出勤履歴一覧',
        historyEdit: '出勤履歴編集',
        historyDetail: '出勤履歴詳細',
        worker: '出勤・退勤',
        hourIndex: '勤怠管理',
        requestIndex: '申請ツール',
        requestCreate: '申請',
        requestEdit: '申請編集',
        calendarIndex: '月報入力カレンダー',
        reportDetail: '報告の詳細',
        hourDetail: '勤怠状況',
        requestDetail: '申請詳細',
        timekeepingIndex: '個人別勤怠日計表',
        distance_fuel: '個人別勤怠日計表',
        contentsIndex: '作業報告フォーマットリスト',
        contentsCreate: 'フォーマット作成',
        contentsEdit: 'フォーマット編集',
        notFound: "見つかりません",
        monthlyReport: '月報参照・申請・印刷',
        monthlyReportManagement: '月報一括参照・出力',
        calendarBooking: '機材予約カレンダー',
        listProduct: '機材一覧表',
        revenue: '機械損料管理表',
        printing: '機材管理者メニュー',
        damageNotDefine: '減損処理',
        workingLoad: {
            construction: '工程管理表－工事別', //'工程総合管理表',
            workload: '負荷管理表',//社員別担当案件確認表',
            worker_charges: '工程管理表－社員別', //'社員別工事スケジュール',
            team_managements: '工程管理表－施工班別', //施工班別工事スケジュール',
            estimate: '見積番号一覧表', //見積管理',
            load: '負荷マスタ',
            team: '施工班', //施工班マスタ',
        }
    },
    Table: {
        column: {
            chooser: " 表示項目を選択する", //,
            noColumn: "表示する項目を選択してください"
        },
        header: {
            row : "Hàng",
            sorting: "並べ替え",
            code: 'コード',
            name: '名前',
            username: 'ユーザーID',
            status: '状況',
            createdAt: '登録日',
            action: '操作',
            content: '内容',
            date: '日時',
            renumeration: "給与",
            salary: "実質労務費",
            construction: '工事',
            drivingLengthOnWorking: '通勤・業務',
            drivingLengthOnPrivate: '私用',
            fuelSupplyAmount: '給油量',
            alcohol_count: 'アルコール数',
            intoxicated_mouth_less: '酒気帯び(0.15mg以上~0.25mg未満)',
            intoxicated_mouth_more: '酒気帯び(0.25mg以上)',
            OT: '残業',
            nightTime: '深夜',
            overNightWorking: '深夜',
            memo: 'メモ',
            memo_calendar: '日時のメモ',
            memo_request: '申請のメモ',
            user: {
                username: 'ユーザーID',
                code: '社員コード',
                name: '従業員名',
                phone: '電話番号',
                positionCode: '役職コード',
                positionName: '役職',
                areaName: 'エリア',
                paidVacationDays: '有給休暇実施日数',
                remain: '有給休暇残日数',
                remainByWorkingOnDayOff: '代休可能日数',
                remainByWorkingOnTimeOff: '代休可能時間',
                dayOffInFuture: '休暇実施予定日',
                index: 'No.',
                resignationDate: '退職日',
                hiddenReport: 'レポート'
            },
            role: {
                code: '役割コード',
                name: '役割',
                permission: 'アクセス許可',
                userCount: 'ユーザー人数'
            },
            position: {
                code: '役職コード',
                name: '役職',
                remuneration: '労務費',
                number: 'ユーザー人数'
            },
            area: {
                code: 'エリアコード',
                name: 'エリア'
            },
            construction: {
                code: '工事コード',
                name: '工事名',
                userName: '従業員名',
                date: '日時',
                address: '住所',
                workTime: '労務時間',
                workTimeIn: '出勤時間',
                workTimeOut: '退勤時間',
                workingTimeIn: '就労開始時刻',
                _workingTimeInOut: '丸めた時刻',
                workingTimeOut: '就労終了時刻',
                _timeConstruction:'現場労務時間',
            
                totalWorkTime: "全現場合計労務時間",
                total: '合計',
                cost: '請負金額',
                amount: '工事予算',
                notCheckOut: '未退勤',
                startTime: '開始時刻',
                endTime: '終了時刻',
                dateStart: '工期始まり',
                dateEnd: '工期終わり',
                _startTime: '就労開始時刻',
                _endTime: '就労終了時刻',
                workingTime: '就労時刻',
                workingTimeInOut: '実際の就労時刻',
                manage: '認証者',
                timeConstruction: '現場労務時間',
                totalPeople: '合計人数',
                totalDate: '合計日数',
                totalProfit: '工事別社員別労務費',
                workTimeReport: '工事時間',
                totalWorkingTime: '労働時間',
            },
            report: {
                title: '件名',
                type: '申請種別'
            },
            labor: {
                profit: "現場労務費",
                workTime: '現場労務時間',
                laborTotal: '合計',
                totalWorkers: '合計人数',
                totalSalary: '合計労働費',
                detail: '詳細'
            },
            hour: {
                totalDays: '出勤日数',
                standardHour: '基準内労働時間',
                workNightHour: "深夜時間",
                morningOT: '早朝残業時間',
                normalOT: '残業時間',
                nightOT: '深夜残業時間',
                statutoryDays_standardHour: '法定内休日労働時間',
                // statutoryDays_workNightHour: "法定内休日深夜時間",
                statutoryDays_morningOT: '法定内休日早朝時間',
                statutoryDays_normalOT: '法定内休日残業時間',
                statutoryDays_nightOT: '法定内休日深夜残業時間',
                nonStatutoryDays_standardHour: '法定外休日労働時間',
                // nonStatutoryDays_workNightHour:"法定外休日深夜時間",
                nonStatutoryDays_morningOT: '法定外休日早朝時間',
                nonStatutoryDays_normalOT: '法定外休日残業時間',
                nonStatutoryDays_nightOT: '法定外休日深夜残業時間',
                lateWork: '遅早',
                absent: "欠勤",
                totalTime: '合計就労時間',
                paidWorkInHoliday: '代休実施日数',
                rosteredDayOff: '代休実施日数（合計）',//代休実施日数',
                contentRequest: '振替休暇',//'振替日',
                work_dayoff_holiday: '休日出勤日数',
                roundedStartTime: '就労開始時間',
                roundedEndTime: '就労終了時間',
                usedPaidHoliday: '有休取得日数',
                usedHolidayInHour: '有休時間',
                usedSpecialHolidayInHour: '特別休暇時間',
                remainedPaidHoliday: '有休残',
                allHours: '労務単価就労時間',
                usedSpecialHolidayInDay: '特別休暇',
                workingInProvisionHoliday: '法定内休日出勤日数',
                workingOutProvisionHoliday: '法定外休日出勤日数',
                workingInHolidays: '休日出勤日数',
                workingOfferOvertime: '事務員残業',
                countCompensatedInProvision: '代休実施日数（法定内）',//'法定内',
                countCompensatedOutProvision: '代休実施日数（法定外）',//'法定外'
            },
            dateRequest: '申請日',
            allow: '状態',
            allowPrimary: '承認状況',
            request: {
                dateDayOff: '振替休日',
                dateRequest: '振替出勤日',
                typeRequest: '申請種別 ',
                manage: '認証者',
                status: 'ステータス',
            },
            timekeeping: {
                driving_length_on_working: '業務走行距離',
                driving_length_on_private: '私用走行距離',
                fuel_supply_amount: '給油量',
                memo: 'メモ',
            },
            monthlyReport: {
                dateType: '種別',
                workHourRange: '勤務時間',
                normalDays: '通常',
                statutoryDays: '有給',
                nonStatutoryDays: '有給',
                rosteredDayOff: '欠勤',
                workOnDayOff: '休出',
                normal: '通常',
                replace: '振出',
                compensated: '振休',
                paidRest: '有休',
                holiday: '休日',
                notWork: '欠勤',
                typeRequestWorkingDay: '申請書'
            },
            index: 'No.'
        }
    },
    Message: {
        deleteData: '全データ削除',
        checkoutconfirm: "退勤しますがよろしいですか？",
        checkoutConfirmTitle: '作業時間の工事割り振り',
        checkoutConfirmContent: '振り分け後必ず「確定」を押す',
        SomethingWrong: "エラーが発生しました。再度お試しください。",
        createDataSuccess: 'データが作成されました。',
        editDataSuccess: 'データが編集されました。',
        deleteDataSuccess: 'データが削除されました。',
        deleteDialogTitle: '選択したデータを削除しますか?',
        notifyErrorTotal: '工事時間と労働時間が間違っていますが、よろしいですか？',
        deleteDateDialogTitle: '選択した日を削除しますが、よろしいでしょうか？',
        deleteDialogContent: 'データが削除されると復旧できませんので、ご注意ください。',
        createGroupSuccess: '班が作成されました。',
        passwordNotMatch: 'パスワードが間違っています。再び入力してください。',
        crearteDataConstruction: 'データを追加しました。',
        failHour: '入力した時間は正しくありません。再確認をお願いします。',
        userName: 'ユーザーIDは6桁から15桁まで入力してください。',
        failedRequest: 'インターネットと接続できませんでした。',
        deleteGroupDataSuccess: 'データが削除されました。',
        erroeName: '役割の名称が存在しました。',
        importExcel: '新規追加された工事現場の数量：${arguments[0]} \n情報が更新された工事現場の数量：　${arguments[1]}',
        excelError: 'Excelファイルをインポートする時、エラーが発生しました。エラーについて以下の表を参照してください。'
    },
    User: {
        username: 'ユーザーID',
        password: 'パスワード',
        remember: 'ユーザー保存',
        signin: 'ログイン'
    },
    DayOff: {
        addDayOff: '休日追加',
        chooseDayOff: '休日選択',
        chooseHourOff: '代休種類',
        chooseTypeSalary: '有給種類',
        remain: "可能日数",
        day: "日",
        hour: '時',
        type: {
            label: '休日種別選択',
            hasSalary: '有給休暇',
            compensatoryLeave: '代休',
            unpaidLeave: '休暇'
        }
    },
    Calendar: {
        notCheckOut: '就労終了時刻はまだ登録されていません。',
        request: '申請',
        report: '報告',
        detailConstruction: '工事詳細',
        createRequest: '申請作成',
        checkIn: '出勤',
        checkOut: '退勤',
        calendar: '出勤予定カレンダー',
        note: '備考',
        requestAllows: '承認申請',
        requestSkip: '非承認申請',
        dayOff: '休日',
        checkInOut: '出勤・退勤',
        construction: '工事現場',
        unRequest: '再申請',
        titleRequest: '作成',
        manageName1: '認証者 1',
        manageName2: '認証者 2',
        listConstruction: '工事現場リスト',
        localizer: "ja",
        today: "今月",
        back: "前へ",
        next: "次へ",
        driving_length_on_working: '業務走行距離',
        driving_length_on_private: '私用走行距離',
        fuel_supply_amount: '給油量',
        memo: 'メモ',
        prevMonthDrivingLength: '先月最終メーター',
        thisMonthDrivingLength: '今月最終メーター',
        slideWorking: 'スライド勤務',

    },
    Construction: {
        choose: '人員配置',
        endDateConstruction: '工事現場の工期が終わりましたので、出勤予定を設定できません', //
        info: '工事現場情報',
        code: '工事コード',
        name: '工事名',
        address: '住所',
        manage: '現場監督',
        content: '工事内容',
        employees: '作業員',
        warning: '作業員と現場監督を選択してください。',
        amount: '請負金額',
        cost: '工事予算',
        profit: '利 益 率(%)',
        contructionProfit: '利 益 率(%)'
    },
    History: {
        detailHistory: '履歴詳細',
        editHistory: '履歴編集',
        coordinates: '座標',
        hour: "時",
        minute: "分"
    },
    Report: {},
    Worker: {
        request: '申請',
        detailConstruction: '工事詳細',
        editCheckIn: '出勤時間編集',
        editCheckOut: '退勤時間編集'
    },
    Forbidden: {
        title: 'このページにアクセス権限がありません。'
    },
    Request: {
        signConstruction: '削除',
        pushConstruction: '追加'
    },
    Exception: {
        deleteData: '注意',
        deleteDataWarning: '全データ削除を行う場合、ユーザーのアカウント以外のデータが全て削除されます。削除されたデータが復元できませんので、ご注意ください。',
        excelTypeError: "インポートファイルはExcelファイルの形ではありません。",
        typeSettingTime: '勤務時間設定', //
        warningCheckOut: 'このユーザーはまだ退勤していません。もう一度確認してください。', //
        all: 'すべて',
        deletedByAdmin: '休暇予定をキャンセルしました。',
        notCheckIn: 'データがありません',
        notCheckOut: 'データがありません',
        typeDayoff: "休暇の種類",
        listRequest: '申請一覧',
        setCalendar: '振替休日申請を承認しました。 ',
        endDate: "終了日は開始日の前の日付にしないでください。",
        outOfEndDateRange: "終了時間は翌日の6:00以降に設定できません",
        timeSevenDate: "１週間以内の平日と休日を振替することができます。",
        locationAlert: "位置情報取得の許可をしてください。",
        title: '件名',
        notificationInOut: '退勤時間を入力してください。',
        errHour: '入力された時間の合計が勤怠時間の上限を超えています。',
        errMinute: '入力された時間の合計が勤怠時間の上限を超えています。',
        history: '出勤履歴',
        errTimeInOut: '労働時間より少ない時間を入力されています。',
        errCheckIn: 'シフトの開始時間を入力してください',
        errCheckInOut: '退勤時間は出勤時間以降にしてください。',
        inputOkCancel: '申請しましたが、操作を続けますか？',
        noData: 'データがありません'
    },
    ComfirmDialog: {
        cancel: 'キャンセル',
    },
    PagingPanel: {
        rowPerPage: "表示",
        of: "件",
        format: "/"
    },

    Backend: {
        DbObject: {
            Id_Required: "Id of Object is required",
            No_Object: "Object not found or Id is incorrect"
        },
        User: {
            User_Code_Exist: "同じユーザー名、社員コードが存在しています。異なるユーザー名、社員コードを入力してください。",
            User_Permission: "ユーザー管理の権限がありません！",
            Del_User_Warn: "出勤履歴があった社員を削除できません。"

        },
        Area: {
            Area_Code_Exist: "重複するエリア名称またはエリアコードが存在しています。",
            Area_Update_Warning: "このエリアを編集できません。",
            Area_Del_Warning: "このエリアを削除できません。",
        },
        Role: {
            Role_Name_Exist: "重複する役割の名称が存在します。異なる名称を入力してください。",
            Permission_Not_Exist: "No permission existed",
            Role_Has_User: "割り当てられているユーザーがある役割を削除できません。"

        },
        Request: {
            New_Req_Warn: " 過去の日に申請することができません。",
            Invalid_Req_Type: "申請種別が正しくありません。再度確認してください。",
            Req_DayOff_Warn: "休日に休暇申請ができません。他の日を選択してください。",
            Req_Holiday_Warn: "この日は既にある休日なので、他の日を選択してください。",
            Choose_ReDate_Warn: "当月以内の平日と休日を振替することができます。",
            User_Req_Check: "User_Req_Check",
            Input_Time_Warn: "遅刻・早退時間は０分より大きい値入力してください。",
            Input_OT_Warn: "終了時間は開始時間以降にしなければなりません。",
            User_Level_Warn: " 認証者を再度選択してください。",
            Slide_Time_User: "スライド勤務が選択されていますので、この申請を作成できません。"

        },
        Position: {
            Position_Code_Exist: "重複する役割の名称が存在します。異なる名称を入力してください。",
            Position_Has_User: "割り当てられているユーザーがある役職を削除できません。"

        },
        Worker: {
            Check_In_Once: "出勤は1日に１回のみ行えます。",
            Not_Check_In: "出勤はまだ行われていません。",
            Expired_Update_Warning: "現在、この項目の編集できません。",
            User_Not_Exist: "このユーザーが存在していません。再度確認してください。",
            No_Position: "現在、ユーザーは役職がありません。",
            No_ConstructionId: "工事現場がないので再度確認をしてください。",
            Update_Check_Out_Warning: "入力する時間は実際の労働時間を超えることができません。",
            ExistCheckinCheckout: "勤務時間がすでに登録されています。ページを更新してください。"
        },
        DayOff: {
            No_Add_Permission: "休暇を追加する権限を持っていません。",
            DayOff_Exist: "この日はすでに休日なので、他の日を選択してください。",
            Out_Of_DayOff: "有給休暇残日数が足りません。",
            Out_Of_DayOff_By_Working_On_DayOff: "代休可能時間が不足しています。再確認をお願いします。",
            DayOff_Warning: "この日はすでに休日なので、他の日を選択してください。",
            Holiday_Warning: "この日はすでに休日なので、他の日を選択してください。",
            Type_Not_Exist: "休日の種類は存在しません。確認して再実行してください。",

            No_Del_Permission: "休暇を削除する権限を持っていません。"
        },
        Construction: {
            No_Add_Permission: "工事現場を追加する権限を持っていません。",
            Construction_Code_Exist: "同じ工事現場コードが存在します。異なる現場コードを入力してください。",
            No_Del_Permission: "工事現場を削除する権限を持っていません。",
            Del_Construction_Warn: "出退勤データがある工事現場を削除できません。",
            Invalid_Schedule: "過去の出勤予定を編集することはできません。 もう一度確認してください。"

        },
        History: {
            Expired_Update_Warn: "出勤履歴の編集可能時間が経過しました。編集したい場合、管理者に連絡してください。",
            Input_Construct_Warn: "Input_Construct_Warn",
            Input_WorkTime_Warn: "入力した数値は労務時間を超えています。",
            Block_Update: '月報申請済みの為、情報は編集できません。',
            Block_Holiday: '有給申請を削除してください。'
        },
        Content: {
            Title_Exist: "同じフォーマットの名称が存在します。異なる名称を入力してください。"
        },
        Delete: {
            Dont_have_permission: " この機能を使う権限がありません。"  
        }
    }

}