import React from "react";
import { I18n } from "react-redux-i18n";
import {
	DateTimeKeyboardField,
	TextField,
	TextFieldOnlyNumber,
	Validation,
	CheckboxField,
} from "components/Forms";
import HistoryAction from "../../../actions/HistoryAction";
import CalendarAction from "../../../actions/CalendarAction";
import ConstructionAction from "../../../actions/ConstructionAction";
import { connect } from "react-redux";
import BaseContainer, { selector } from "containers/BaseContainer";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import EventWorkingLog from "./event.workingLog";
import Select from "react-select";
import common from "common";

import MenuList from "./MenuList";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";

const MILLISECONDS_IN_24_HOURS = 86400000;
const MILLISECONDS_IN_6_HOURS = 21600000;
const MILLISECONDS_IN_1_HOURS = 3600000;
class UpdateContructionTime extends BaseContainer {
	constructor(props) {
		super(props);
		this.validate = {
			length: [Validation.required(I18n.t("Form.required"))],
		};
		this.state = {
			getAllConstructionDropDown: this.props.getAllConstructionDropDown,
			getConstructionWithoutExist: [],
			getConstructionId: [],
			getConstructionName: [],
			reload: false,
			updateThisMonth: false,
			inputs: [],
			isAddConstructionInput: false,
			addConstructionInput: "",
			index: this.props.index,
			data: {},
			startTime: null,
			endTime: null,
			totalTime: undefined,
			totalHour: 0,
			totalMinute: 0,
			itemUpdate: [],
			timeUpdate: [],
			timeWorkEveryConstruction: [],
			hoursShow: 0,
			minutesShow: 0,
			constructions: [],
			updateWorkingDriver: false,
			slideTime:
				this.props.workingLogItem &&
				this.props.workingLogItem.slideTime !== undefined
					? this.props.workingLogItem.slideTime
					: null,
			_checkErrTime: false,
			changeUpdateTime: false,
			newStartTime: null,
			newEndTime: null,
			alcohol: this.props.workingLogItem &&
							 this.props.workingLogItem.slideTime !== undefined
							 ? this.props.workingLogItem.alcohol
							 : null,
		};
		this.validate = {
			hours: [
				Validation.min(0, I18n.t("Form.min")),
				Validation.max(24, I18n.t("Form.max")),
				Validation.required(I18n.t("Form.required")),
			],
			minutes: [
				Validation.min(0, I18n.t("Form.min")),
				Validation.max(60, I18n.t("Form.max")),
				Validation.required(I18n.t("Form.required")),
			],
			checkOut: [Validation.required(I18n.t("Form.required"))],
			alcohol: [
				(value) => {
					const floatValue = parseFloat(value);
					if (value.startsWith('-') || isNaN(floatValue) || floatValue < 0 || (!/^\d*\.\d*$/.test(value) && value != 0)){
						return I18n.t("Form.min").replace('${arguments[0]}', '0');
					}
			 },
			 (value) => {
					const floatValue = parseFloat(value);
					if (floatValue > 1) {
						 return I18n.t("Form.max").replace('${arguments[0]}', '1');
					}
			 },
			]
		};
		this.ConfirmDialog = null;
		this.onHandleChange = this.onHandleChange.bind(this);
		this._checkErrStart = false;
		this.onChangeStartEndTime = this.onChangeStartEndTime.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onAddConstruction = this.onAddConstruction.bind(this);
	}

	onCancel() {
		return this.props.onCancel();
	}

	updateValidate(startTime) {
		this.state.timeStartValidate = [
			Validation.min(0, `最小値が ${startTime} で入力してください。`),
			Validation.required(I18n.t("Form.required")),
		];
	}

	onHandleChange(isAddConstructionInput, value, name, index) {
		if (name === "hours") {
			this.state.timeUpdate[index].hours = Number(value);
		}
		if (name === "minutes") {
			this.state.timeUpdate[index].minutes = Number(value);
		}
		this.state.timeWorkEveryConstruction[index] =
			Number(this.state.timeUpdate[index].hours) * 3600 * 1000 +
			Number(this.state.timeUpdate[index].minutes) * 60 * 1000;

		this.setState({
			timeWorkEveryConstruction: this.state.timeWorkEveryConstruction,
		});
		let totalWorkConstruction = 0;
		this.state.timeWorkEveryConstruction.map((item) => {
			totalWorkConstruction += item;
		});

		let checkTotalTime = parseInt(this.state.totalTime - totalWorkConstruction);

		let handleTotalTime = common.msToTime(checkTotalTime);
		this.state.hoursShow = handleTotalTime.hours;
		this.state.minutesShow = handleTotalTime.minutes;
	}

	updateItemConstructions(constructions) {
		let updateItemConstructions = [],
			timeItemConstructions = [],
			timeWorkEveryConstruction = [];
		if (constructions !== null && constructions !== undefined) {
			if (Array.isArray(constructions)) {
				constructions.map((item, index) => {
					updateItemConstructions[index] = item;
					let workTime = _.get(item, "workTime", 0);

					timeWorkEveryConstruction[index] = workTime;

					let handleHoursWork = common.msToTime(workTime);
					let hoursWork = handleHoursWork.hours;
					let minutesWork = handleHoursWork.minutes;

					timeItemConstructions[index] = {
						hours: hoursWork,
						minutes: minutesWork,
					};
					this.setState({
						itemUpdate: updateItemConstructions,
						timeWorkEveryConstruction: timeWorkEveryConstruction,
						timeUpdate: timeItemConstructions,
					});
				});
			} else {
				updateItemConstructions[0] = { constructionId: constructions._id };
				timeWorkEveryConstruction[0] = 0;
				timeItemConstructions[0] = { hours: 0, minutes: 0 };
				this.setState({
					itemUpdate: updateItemConstructions,
					timeWorkEveryConstruction: timeWorkEveryConstruction,
					timeItemConstructions,
				});
			}
		} else {
			updateItemConstructions[0] = { constructionId: null };
			timeWorkEveryConstruction[0] = 0;
			timeItemConstructions[0] = { hours: 0, minutes: 0 };
			this.setState({
				itemUpdate: updateItemConstructions,
				timeWorkEveryConstruction: timeWorkEveryConstruction,
				timeUpdate: timeItemConstructions,
			});
		}
	}

	componentWillMount() {
		let { constructions } = this.props;
		this.updateItemConstructions(constructions);
		let userCalendar = localStorage.getItem("userCalendar");
		let startDate = this.props.startEvent;
		let checkTimeZoneFE = localStorage.getItem("frontTimeZone");

		this.props
			.dispatch(
				CalendarAction.getSettingTimeUser({
					userId: userCalendar,
					startDate: startDate,
					checkTimeZoneFE: checkTimeZoneFE
				})
			)
			.then((response) => {
				if (!response.error) {
					this.setState({ settingTime: response.data });
				}
			});

		if (this.props.idRequest) {
			this.props
				.dispatch(HistoryAction.fetch({ _id: this.props.idRequest }))
				.then((response) => {
					if (!response.error) {
						this.setState({ data: response.data });
					}
				});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.state.totalTime > 0) {
			let totalWorkConstruction = 0;
			this.state.timeWorkEveryConstruction.map((item) => {
				if (item !== undefined) totalWorkConstruction += item;
			});

			let checkTotalTime = parseInt(
				this.state.totalTime - totalWorkConstruction
			);
			let handleTotalTime = common.msToTime(checkTotalTime);
			this.state.hoursShow = handleTotalTime.hours;
			this.state.minutesShow = handleTotalTime.minutes;
		}

		if (
			this.props.constructions != null &&
			this.props.constructions.length !== prevProps.constructions.length
		) {
			this.setState({
				isAddConstructionInput: !this.state.isAddConstructionInput,
				inputs: [],
				timeUpdate: [],
				timeWorkEveryConstruction: [],
			});
			this.updateAfterHandle();
		}
	}

	updateAfterHandle() {
		let userCalendar = localStorage.getItem("userCalendar");
		let dateCalendar = localStorage.getItem("dateCalendar");
		let yearMonth = moment(new Date()).format("YYYY-MM");
		if (dateCalendar) {
			yearMonth = moment(dateCalendar).format("YYYY-MM");
		}
		this.props.dispatch(
			CalendarAction.getSettingTimeUser({ userId: userCalendar })
		);
		this.props.dispatch(ConstructionAction.listHolidayContruction());
		this.props.dispatch(CalendarAction.drivingLog({ yearMonth }));
		this.props.dispatch(
			CalendarAction.listConstructionCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		); // get User, Construction in Calendar,
		this.props.dispatch(
			CalendarAction.listDriverLengthCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listHistoryCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listWorkerRequestsCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
		this.props.dispatch(
			CalendarAction.listUserDayOffCalendar({
				userId: userCalendar,
				yearMonth: yearMonth,
			})
		);
	}

	notifyErrorMessage(err) {
		switch (err.status) {
			case 422: {
				if (err.message === "Id_Required") {
					this.notify(I18n.t("Backend.DbObject.Id_Required"), "error");
				}
				if (err.message === "Input_WorkTime_Warn") {
					this.notify(I18n.t("Backend.History.Input_WorkTime_Warn"), "error");
				}
				break;
			}
			case 404: {
				if (err.message === "No_Object") {
					this.notify(I18n.t("Backend.DbObject.No_Object"), "error");
				}
				break;
			}
			case 405: {
				if (err.message === "Expired_Update_Warn") {
					this.notify(I18n.t("Backend.History.Expired_Update_Warn"), "error");
				}
				break;
			}
			case 412: {
				if (err.message === "Input_Construct_Warn") {
					this.notify(I18n.t("Backend.History.Input_Construct_Warn"), "error");
				}
				break;
			}
			case 417: {
				if (err.message === "Block_Update") {
					this.notify(I18n.t("Backend.History.Block_Update"), "error");
				}
				break;
			}
			default:
				this.notify(`Response: [${err.status}] ${err.message}`, "error");
		}
	}

	onAddConstruction = (item, index) => {
		this.state.itemUpdate[index] = item ? { value: item.value } : { item };
		this.setState({
			itemUpdate: this.state.itemUpdate,
			reload: !this.state.reload,
		});
	};

	renderDialogConfirmDelete() {
		return (
			<ConfirmDialog
				ref={(ref) => (this.ConfirmDialog = ref)}
				title={I18n.t("Message.deleteDialogTitle")}
				content={I18n.t("Message.deleteDialogContent")}
				onSubmit={this.onDeleteConstruction}
			/>
		);
	}

	onDeleteConstruction = (data) => {
		let permissions = JSON.parse(localStorage.user).permissions || [];
		if (data.deleteConstruction) {
			if (data.index !== undefined) {
				delete this.state.itemUpdate[data.index];
				delete this.state.timeWorkEveryConstruction[data.index];
				delete this.state.inputs[data.index - 1];
				this.setState({
					itemUpdate: this.state.itemUpdate,
					timeWorkEveryConstruction: this.state.timeWorkEveryConstruction,
					reload: !this.state.reload,
				});
			}
		} else {
			let checkTimeZoneFE = localStorage.getItem("frontTimeZone");
			this.props
				.dispatch(
					HistoryAction.deleteHistoryInCalendar({
						idRequest: data,
						permissions: permissions,
						checkTimeZoneFE: checkTimeZoneFE
					})
				)
				.then((response) => {
					if (!response.error) {
						this.onCancel();
						this.updateAfterHandle();
						this.notify(I18n.t("Message.editDataSuccess"));
					} else {
						this.notifyErrorMessage(response.error);
					}
				});
		}
	};

	appendInput() {
		let newInput = `input-${this.state.inputs.length}`;
		this.state.inputs.push(newInput);
		this.setState((prevState) => ({ inputs: this.state.inputs }));

		let countConstruction = 0;
		if (Array.isArray(this.props.constructions)) {
			countConstruction = this.props.constructions.length - 1;
		}
		let addInputCount = this.state.inputs.length;
		this.state.timeUpdate[countConstruction + addInputCount] = {
			hours: 0,
			minutes: 0,
		};

		this.setState({
			isAddConstructionInput: this.state.isAddConstructionInput,
			indexAppendInput: countConstruction + addInputCount,
			timeUpdate: this.state.timeUpdate,
			inputs: this.state.inputs,
		});
	}

	onChangeStartEndTime(value, name) {
		let newValue = moment(value).format();
		this.setState({ changeUpdateTime: true });
		if (name === "startTime") {
			this.setState({ startTime: newValue });
		}
		if (name === "endTime") {
			this.setState({ endTime: newValue });
		}
	}

	onChangeAlcohol(value) {
		this.setState({ alcohol: value.replace(',', '.') });
	}

	renderConstruction(isAddConstructionInput, updateThisMonth, index) {
		this.state.hours = 0;
		this.state.minutes = 0;
		if (this.state.timeWorkEveryConstruction[index]) {
			let handleHoursWork = common.msToTime(
				this.state.timeWorkEveryConstruction[index]
			);
			this.state.hours = handleHoursWork.hours;
			this.state.minutes = handleHoursWork.minutes;
		}

		return (
			<Grid item style={{ clear: "both" }}>
				<Grid item xs={12} lg={6} style={{ float: "left" }}>
					<TextFieldOnlyNumber
						fullWidth
						disabled={!updateThisMonth}
						label={I18n.t("History.hour")}
						margin="normal"
						name={`constructions[${index}][hours]`}
						onChange={(value) =>
							this.onHandleChange(isAddConstructionInput, value, "hours", index)
						}
						defaultValue={this.state.hours || "00"}
						validate={this.validate.hours}
					/>
				</Grid>

				<Grid item xs={12} lg={6} style={{ float: "left" }}>
					<TextFieldOnlyNumber
						fullWidth
						disabled={!updateThisMonth}
						label={I18n.t("History.minute")}
						name={`constructions[${index}][minutes]`}
						defaultValue={this.state.minutes || "00"}
						onChange={(value) =>
							this.onHandleChange(
								isAddConstructionInput,
								value,
								"minutes",
								index
							)
						}
						validate={this.validate.minutes}
					/>
				</Grid>
				{updateThisMonth ? (
					<Grid
						item
						xs={12}
						lg={6}
						style={{ float: "right", cursor: "pointer" }}
					>
						<DeleteIcon
							onClick={() =>
								this.ConfirmDialog.show({
									id: this.state.itemUpdate[index],
									index: index,
									deleteConstruction: true,
								})
							}
							style={{ color: "red" }}
						/>
					</Grid>
				) : (
					""
				)}
			</Grid>
		);
	}

	componentDidMount() {
		this.props
			.dispatch(
				ConstructionAction.getAllConstructionDropDown({
					choiceGetValue: "name",
					pageSize: -1,
				})
			)
			.then((response) => {
				if (
					response.error == null &&
					response.data.data !== undefined &&
					response.data.data.length > 0
				) {
					this.setState({ getConstructionName: response.data.data });
				}
			});

		this.props
			.dispatch(
				ConstructionAction.getAllConstructionDropDown({
					choiceGetValue: "code",
					pageSize: -1,
				})
			)
			.then((response) => {
				if (
					response.error == null &&
					response.data.data !== undefined &&
					response.data.data.length > 0
				) {
					this.setState({ getConstructionId: response.data.data });
				}
			});
	}

	renderAutoCompleteField(construction, index) {
		let showConstructionsId = "";
		let showConstructionCode = [];
		let showConstructionName = [];
		if (this.state.itemUpdate[index] && this.state.itemUpdate[index] !== null)
			showConstructionsId =
				this.state.itemUpdate[index].value &&
				this.state.itemUpdate[index].value !== undefined
					? this.state.itemUpdate[index].value
					: "";
		if (!showConstructionsId && construction !== undefined)
			showConstructionsId = construction.constructionId;

		let code = null,
			name = null;
		if (showConstructionsId !== null && showConstructionsId !== undefined) {
			this.state.getConstructionWithoutExist.find((item) => {
				if (item._id === showConstructionsId) {
					name = item.name;
					code = item.code;
					return;
				}
			});
		}

		showConstructionCode = { value: showConstructionsId, label: code };
		showConstructionName = { value: showConstructionsId, label: name };

		return (
			<Grid container spacing={16}>
				<TextField
					type="hidden"
					name={`constructions[${index}][constructionId]`}
					defaultValue={`${showConstructionsId}`}
				/>

				<Grid item xs={12} md={6}>
					<Select
						key="1"
						isClearable
						backspaceRemovesValue
						components={{ MenuList }}
						options={this.state.getConstructionId}
						value={showConstructionCode}
						onChange={(value) => this.onAddConstruction(value, index)}
						onInputChange={(inputValue) => {
							return inputValue.replace(/[^0-9]/g, "");
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Select
						key="1"
						components={{ MenuList }}
						isClearable
						options={this.state.getConstructionName}
						value={showConstructionName}
						onChange={(value) => this.onAddConstruction(value, index)}
					/>
				</Grid>
			</Grid>
		);
	}
	updateAllData = () => {
		this.setState({
			updateWorkingDriver: false,
			reload: true,
		});
	};

	updateWorkingDriverLog = () => {
		this.setState({
			updateWorkingDriver: true,
			reload: true,
		});
	};

	onChangeSlideTime = (value) => {
		this.setState({ slideTime: value, changeUpdateTime: true });
	};

	render() {
		let {
			workingLogItem,
			startEvent,
			constructions,
			getAllConstructionDropDown,
			listDriverLengthCalendar,
		} = this.props;

		this.state.updateThisMonth = true;
		let {
			updateThisMonth,
			getConstructionWithoutExist,
			isAddConstructionInput,
		} = this.state;

		if (getConstructionWithoutExist.length === 0) {
			this.state.getConstructionWithoutExist = getAllConstructionDropDown;
		}

		let { startTime, endTime, idRequest, getRequests, classes, onCancel } =
			this.props;
		let { data } = this.state;
		this.state.newStartTime = this.state.startTime || startTime;
		this.state.newEndTime = this.state.endTime || endTime;
		let settingNow = {};

		if (this.state.newStartTime === null && startTime === null) {
			this.state.newStartTime = moment(
				new Date(startEvent).setHours(8, 0)
			).utc();
		}

		if (this.state.newEndTime === null && endTime === null) {
			this.state.newEndTime = moment(
				new Date(startEvent).setHours(17, 0)
			).utc();
		}

		if (this.state.slideTime) {
			settingNow = this.props.settingTimeUser.settingSlide;
		} else {
			settingNow = this.props.settingTimeUser.setting;
		}

		let errors = {};
		if (settingNow) {
			let overTimeBeforeStart = _.get(settingNow, "overTimeBeforeStart", "");
			let overTimeNightEnd = _.get(settingNow, "overTimeNightEnd", "");
			console.log(
				moment(this.state.newStartTime).format("HH:mm"),
				moment(overTimeBeforeStart).format("HH:mm")
			);
			if (
				!this.state.slideTime &&
				overTimeBeforeStart !== "" &&
				moment(this.state.newStartTime).utc() <
					moment(overTimeBeforeStart).utc()
			) {
				errors.startTime =
					"開始時刻は、シフト作業の開始時刻よりも短くないでください。";
			}

			if (moment(this.state.newEndTime) < moment(this.state.newStartTime)) {
				errors.endTime = I18n.t("Exception.endDate");
				this.state._checkErrTime = true;
			} else {
				this.state._checkErrTime = false;
			}

			//http://redmine.javis.vn/issues/13349
			if (
				!this.state.slideTime &&
				!moment(this.state.newEndTime).isBefore(moment(overTimeNightEnd)) &&
				moment(this.state.newEndTime).utc().format("HH:mm") !==
					moment(overTimeNightEnd).utc().format("HH:mm")
			) {
				errors.endTime = I18n.t("Exception.errCheckInOut");
				this.state._checkErrTime = true;
			}
		}

		let signTime = undefined,
			lastTotalTime = 0,
			totalWorkConstruction = 0,
			countConstruction = 0,
			disableRegister = false;

		if (data._id) {
			let timeProps = _.get(data, "totalTime", "");
			if (
				timeProps &&
				timeProps !== undefined &&
				!this.state.changeUpdateTime
			) {
				lastTotalTime = timeProps;
			} else {
				if (data.setting) {
					signTime = common.calculationRestingTime(
						this.state.newStartTime,
						this.state.newEndTime,
						data.setting,
						this.state.slideTime,
						this.state.settingTime?.isHoliday
					);
				} else {
					signTime = common.calculationRestingTime(
						this.state.newStartTime,
						this.state.newEndTime,
						settingNow,
						this.state.slideTime,
						this.state.settingTime?.isHoliday
					);
				}
				lastTotalTime = signTime !== undefined ? signTime : timeProps;
			}
		} else {
			if (data.setting) {
				signTime = common.calculationRestingTime(
					this.state.newStartTime,
					this.state.newEndTime,
					settingNow,
					this.state.slideTime,
					this.state.settingTime?.isHoliday
				);
			} else {
				signTime = common.calculationRestingTime(
					this.state.newStartTime,
					this.state.newEndTime,
					settingNow,
					this.state.slideTime,
					this.state.settingTime?.isHoliday
				);
			}
			lastTotalTime = signTime !== undefined ? signTime : 0;
		}
		this.state.totalTime = lastTotalTime;

		this.state.timeWorkEveryConstruction.map((item) => {
			totalWorkConstruction += item;
		});
		let checkTotalTime = parseInt(this.state.totalTime - totalWorkConstruction);
		let handleTotalTime = common.msToTime(checkTotalTime);
		this.state.hoursShow = handleTotalTime.hours;
		this.state.minutesShow = handleTotalTime.minutes;

		if (Array.isArray(this.props.constructions)) {
			countConstruction = constructions.length;
		} else {
			countConstruction = 1;
		}

		if (lastTotalTime > MILLISECONDS_IN_24_HOURS) {
			errors.endTime = "労働時間は24時間を超えることはできません";
			this.state._checkErrTime = true;
		}

		disableRegister =
			this.state.hoursShow < 0 ||
			this.state.minutesShow < 0 ||
			(this.state.itemUpdate[0] === null && this.state.itemUpdate.length === 1)
				? true
				: false;

		let userCalendar = localStorage.getItem("userCalendar");
		let permissions = JSON.parse(localStorage.user).permissions || [];

		let mana_authority_update = false;
		for (let i of permissions) {
			switch (i) {
				case "mana_authority_update":
					mana_authority_update = true;
					break;
				default:
					break;
			}
		}

		let checkUserLogin = false;
		if (JSON.parse(localStorage.user)._id === userCalendar) {
			checkUserLogin = true;
		}

		return (
			<React.Fragment>
				<Grid>
					<div>
						<Grid>
							{
								<Grid container>
									<TextField
										type="hidden"
										name="totalTime"
										defaultValue={`${this.state.totalTime}`}
									/>
									<TextField
										type="hidden"
										name="start"
										defaultValue={`${moment(startEvent).format("YYYY-MM-DD")}`}
									/>
									<TextField
										type="hidden"
										name="updateWorkingDriver"
										defaultValue={`${this.state.updateWorkingDriver}`}
									/>
									<Grid item xs={12} lg={6}>
										{!!errors.startTime ? (
											<DateTimeKeyboardField
												style={{ paddingRight: "10px" }}
												format="YYYY/MM/DD HH:mm"
												fullWidth
												label={I18n.t("Input.hourCheckIn")}
												name="startTime"
												error={!!errors.startTime}
												helperText={errors.startTime}
												clearable={false}
												value={this.state.newStartTime}
												disabled={!updateThisMonth}
												showDate={true}
												onChange={(value) =>
													this.onChangeStartEndTime(value, "startTime")
												}
											/>
										) : (
											<DateTimeKeyboardField
												style={{ paddingRight: "10px" }}
												format="YYYY/MM/DD HH:mm"
												fullWidth
												label={I18n.t("Input.hourCheckIn")}
												name="startTime"
												value={this.state.newStartTime}
												disabled={!updateThisMonth}
												clearable={false}
												showDate={true}
												onChange={(value) =>
													this.onChangeStartEndTime(value, "startTime")
												}
											/>
										)}
									</Grid>
									<Grid item xs={12} lg={6}>
										{!!errors.endTime ? (
											<DateTimeKeyboardField
												style={{ paddingRight: "10px" }}
												format="YYYY/MM/DD HH:mm"
												fullWidth
												label={I18n.t("Input.hourCheckOut1")}
												name="endTime"
												error={true}
												helperText={errors.endTime}
												clearable={false}
												value={this.state.newEndTime}
												disabled={!updateThisMonth}
												showDate={true}
												onChange={(value) =>
													this.onChangeStartEndTime(value, "endTime")
												}
											/>
										) : (
											<DateTimeKeyboardField
												style={{ paddingRight: "10px" }}
												format="YYYY/MM/DD HH:mm"
												fullWidth
												label={I18n.t("Input.hourCheckOut")}
												name="endTime"
												value={this.state.newEndTime}
												disabled={!updateThisMonth}
												clearable={false}
												showDate={true}
												onChange={(value) =>
													this.onChangeStartEndTime(value, "endTime")
												}
											/>
										)}
									</Grid>
								</Grid>
							}
						</Grid>
						<Grid container>
							<Grid item xs={12} lg={6}>
								{this.state.hoursShow < 0 || this.state.minutesShow < 0 ? (
									<TextField
										fullWidth
										name="time"
										label={I18n.t("Input.totalTime")}
										defaultValue={`${this.state.hoursShow}:${this.state.minutesShow}`}
										disabled
										error={true}
									/>
								) : (
									<TextField
										fullWidth
										name="time"
										label={I18n.t("Input.totalTime")}
										defaultValue={`${this.state.hoursShow}:${this.state.minutesShow}`}
										disabled
									/>
								)}
							</Grid>
							<Grid item xs={12} lg={6}>
								<CheckboxField
									style={{ paddingTop: "16px" }}
									label={I18n.t("Input.construction.slideTime")}
									name="slideTime"
									checked={this.state.slideTime ? this.state.slideTime : false}
									onChange={(value) => this.onChangeSlideTime(value)}
								/>
							</Grid>
						</Grid>
						<br></br>
					</div>
				</Grid>
				<Grid>
					<Grid>
						<p>工事に時間を振リ分けてください</p>
					</Grid>
					<Grid style={{ marginTop: "10px" }}>
						{constructions !== null ? (
							<Grid>
								<Grid item xs={12} lg={12}>
									<Grid>
										{Array.isArray(constructions) ? (
											updateThisMonth ? (
												this.state.itemUpdate.map((element, index) => {
													if (
														element === null ||
														constructions[index] === undefined
													)
														return;
													return (
														<Grid key={index}>
															{this.renderAutoCompleteField(element, index)}
															{this.renderConstruction(
																this.state.isAddConstructionInput,
																updateThisMonth,
																index
															)}
														</Grid>
													);
												})
											) : (
												this.state.itemUpdate.map((element, index) => {
													if (element === null) return;
													return (
														<Grid key={index}>
															<Grid
																item
																xs={12}
																lg={6}
																style={{ float: "left", clear: "both" }}
															>
																<p>
																	{I18n.t("Input.construction.code")}:{" "}
																	{element.code}
																</p>
																<p>
																	{I18n.t("Input.construction.name")}:{" "}
																	{element.name}
																</p>
															</Grid>
															<Grid
																item
																xs={12}
																lg={5}
																style={{ float: "right" }}
															>
																{this.renderConstruction(
																	this.state.isAddConstructionInput,
																	updateThisMonth,
																	index
																)}
															</Grid>
														</Grid>
													);
												})
											)
										) : (
											<Grid key={0}>
												{this.renderAutoCompleteField(undefined, 0)}
												{this.renderConstruction(
													this.state.isAddConstructionInput,
													updateThisMonth,
													0
												)}
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						) : (
							<Grid key={0}>
								{this.renderAutoCompleteField(undefined, 0)}
								{this.renderConstruction(
									this.state.isAddConstructionInput,
									updateThisMonth,
									0
								)}
							</Grid>
						)}
					</Grid>
					{this.props.notExistStart && updateThisMonth ? (
						<Grid>
							{this.state.inputs.map((input, indexInput) => (
								<Grid container spacing={16} key={input}>
									{this.renderAutoCompleteField(
										this.state.itemUpdate[countConstruction + indexInput] || [],
										countConstruction + indexInput
									)}
									{this.renderConstruction(
										this.state.isAddConstructionInput,
										true,
										countConstruction + indexInput
									)}
								</Grid>
							))}
							<Grid style={{ clear: "both", width: "100%", height: "3em" }}>
								<Button
									variant="outlined"
									onClick={this.appendInput.bind(this)}
									style={{ marginRight: "0.5rem", float: "right" }}
								>
									{I18n.t("Button.addCreateConstruction")}
								</Button>
							</Grid>
						</Grid>
					) : (
						""
					)}
					<Grid container>
						<Grid item xs={12} lg={6} style={{ paddingRight: "10px" }}>
							<TextField     
								fullWidth  
								validate={this.validate.alcohol}                    
								label={`${I18n.t('Label.alcohol')}`}
								value={this.state.alcohol}
								name="alcohol"
								onChange={(value) => this.onChangeAlcohol(value)}
							/>
						</Grid>
					</Grid>
					{this.renderDialogConfirmDelete()}
					<EventWorkingLog
						innerRef={(ref) => (this.EventWorkingLog = ref)}
						workingLogItem={workingLogItem}
						listDriverLengthCalendar={listDriverLengthCalendar}
						classes={classes}
						getRequests={getRequests}
						idRequest={idRequest}
						startEvent={startEvent}
						onCancel={onCancel}
						onResetAfterSubmit={this.onResetAfterSubmit}
						updateThisMonth={this.state.updateThisMonth}
					/>
					{updateThisMonth ? (
						<Grid style={{ marginBottom: "1em", textAlign: "center" }}>
							<Button
								disabled={!checkUserLogin && !mana_authority_update}
								variant="outlined"
								type="submit"
								onClick={this.updateWorkingDriverLog}
							>
								{I18n.t("Button.saveFourField")}
							</Button>
							<Button
								variant="outlined"
								type="submit"
								onClick={(e) => {
									this.updateAllData();
								}}
								disabled={
									(!checkUserLogin && !mana_authority_update) ||
									this._checkErrStart ||
									this.state._checkErrTime ||
									disableRegister ||
									this._checkErrWithout24h ||
									this.props.flagSubmit
								}
								style={{ marginLeft: "5px", alignItems: "center" }}
							>
								{I18n.t("Button.saveAll")}
							</Button>
							<Button
								variant="outlined"
								onClick={this.onCancel}
								style={{ marginLeft: "5px", alignItems: "center" }}
							>
								{I18n.t("Button.cancel")}
							</Button>
							<Button
								disabled={
									!idRequest || (!checkUserLogin && !mana_authority_update)
								}
								variant="outlined"
								onClick={() => this.ConfirmDialog.show(idRequest)}
								style={{ marginLeft: "5px", alignItems: "center" }}
							>
								{I18n.t("Button.delete")}
							</Button>
						</Grid>
					) : (
						""
					)}
				</Grid>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		UpdateContructionTime: selector(
			state,
			"calendar.UpdateContructionTime",
			[]
		),
		settingTimeUser: selector(state, "calendar.getSettingTimeUser", []),
	};
};
export default connect(mapStateToProps)(UpdateContructionTime);
